import * as yup from 'yup';

import { schemaPassword } from 'utils/sharedSchemas';
import { schemaError } from 'utils/schemaError';

export const ProfilePasswordSchema = yup.object().shape({
  oldPassword: schemaPassword,
  newPassword: schemaPassword,
  newPasswordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword')], 'Пароль має співпадати із значенням поля “Новий пароль“'),
});

export const ProfilePasswordErrorSchema = schemaError({
  default: 'Помилка паролю',
  oldPassword: {
    default: 'Помилка паролю',
    invalid: 'Невірний пароль',
  },
});
