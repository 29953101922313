import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, FormElement } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IDevice } from 'services/api/tms/devices/types';

import { useDeviceTags } from 'features/hardware/routes/Tags/hooks/useDeviceTags';
import { DeviceEditFormSchema } from './DeviceEditFormSchema';

export interface IDeviceEditForm {
  defaultValues: Partial<IDevice>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const DeviceEditForm: FC<IDeviceEditForm> = ({ defaultValues, onCancel, onSubmit }) => {
  const { tags } = useDeviceTags();

  return (
    <Form<any>
      validate={yupResolver(DeviceEditFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field.Select
            name="tagIds"
            placeholder="Оберіть категорії"
            label="Категорії"
            isMulti
            options={tags}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
          />
          <Field.TextArea name="note" label="Примітка" />

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="dark" onClick={onCancel}>
              Скасувати
            </Button>
            <Button width="wide" color="dark" type="submit">
              Зберегти
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
