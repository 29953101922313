import { Outlet } from 'react-router-dom';
import { Paragraph, Title } from '@smart-kasa/ui';

import Device from 'assets/device.png';
import styles from './AuthLayout.module.scss';

const Wrapper = (props) => <div {...props} className={styles.wrapper} />;
const Sidebar = () => (
  <div className={styles.sidebar}>
    <div className={styles.slogan}>
      <img src={Device} alt="Device" className={styles.device} />

      <Title variant="h1" className={styles.heading}>
        Приймайте Ваші платежі будь-де⚡
      </Title>
      <Paragraph>Мобільність та зручність</Paragraph>
      <Paragraph>Програмний РРО</Paragraph>
      <Paragraph>Автономність</Paragraph>
      <Paragraph>Синхронізація через хмару</Paragraph>
      <Paragraph>Робота з BAR та QR кодами</Paragraph>
    </div>
  </div>
);

const Content = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
  </div>
);

export const AuthLayout = () => (
  <Wrapper>
    <Sidebar />
    <Content>
      <Outlet />
    </Content>
  </Wrapper>
);
