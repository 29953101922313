import { tmsApi } from '../api';
import type { CoreResponse, Pagination } from 'types';
import type { IEvent, EventFilterQuery } from './types';

export const eventsApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Event'] }).injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<CoreResponse<IEvent[]>, Pagination<IEvent> & EventFilterQuery>({
      query: (params) => ({ url: '/events', params }),
      providesTags: ['Event'],
    }),
    findEvent: builder.query<CoreResponse<IEvent>, string>({
      query: (id) => ({ url: `/events/${id}` }),
      providesTags: ['Event'],
    }),
  }),
});

export const { useGetEventsQuery, useFindEventQuery } = eventsApi;
