import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Row, Col, Pagination, InlineSpace } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { Panel, Description } from 'components';
import { useGetEventsQuery } from 'services/api/tms/events/api';

import { EventTable } from '../..//components/EventTable';

export const EventList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: events, isLoading } = useGetEventsQuery(query);

  return (
    <Fragment>
      <PageHeader>Надіслані події</PageHeader>

      <Row gutter={10}>
        <Col span={8}>
          <Panel>
            <Description label="Створено / оброблено">9531 / 8992</Description>
          </Panel>
        </Col>
        <Col span={8}>
          <Panel>
            <Description label="Кількість подій за сьогодні">128</Description>
          </Panel>
        </Col>
        <Col span={8}>
          <Panel>
            <Description label="Відсоток успішних подій">91.77%</Description>
          </Panel>
        </Col>
      </Row>

      <InlineSpace direction="vertical" size={20} />

      <EventTable
        data={events?.data}
        isLoading={isLoading}
        onRowClick={(event) => navigate(ROUTES.eventShow(event.id))}
      />

      <Pagination
        totalItems={events?.meta?.totalCount}
        currentPage={events?.meta?.currentPage}
        perPage={events?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
