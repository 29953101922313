import { Route, Routes } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { ModelList } from './routes/ModelList';
import { ModelCreate } from './routes/ModelCreate';
import { ModelEdit } from './routes/ModelEdit';

export const Models = () => (
  <Routes>
    <Route element={<ModelList />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="new" element={<ModelCreate />} />
        <Route path=":id/edit" element={<ModelEdit />} />
      </Route>
    </Route>
  </Routes>
);
