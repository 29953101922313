import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IBank } from 'services/api/tms/banks/types';

import { BankFormSchema } from './BankFormSchema';

interface IBankForm {
  defaultValues: Partial<IBank>;
  onSubmit: (fd: any) => void;
}

export const BankForm: FC<IBankForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      validate={yupResolver(BankFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={4}>
              <Field.Uploader name="logo" accept=".png, .jpg, .jpeg" />
            </Col>
            <Col span={10}>
              <Field.Input name="name" label="Назва" required />
              <Field.Input name="registrationNumber" label="ЄДРПОУ" />
              <Field.Input name="email" label="Поштова скринька" />
            </Col>
            <Col span={10}>
              <Field.Input name="fullName" label="Юридична назва" />
              <Field.Input name="phoneNumber" label="Номер телефону" />
              <Field.Input name="website" label="Веб-сайт" />
            </Col>
          </Row>

          <FormElement direction="row" justifyContent="flex-end">
            <Button type="submit" color="dark" width="narrow">
              {formatMessage({ id: 'buttons.save' })}
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
