import { FC, useState } from 'react';
import { useMatch } from 'react-router-dom';
import cs from 'classnames';
import { Menu, IMenuItemProps } from '@smart-kasa/ui';

import styles from './Navigation.module.scss';

export interface IMenuGroupProps extends IMenuItemProps {
  namespace?: string;
}

export const MenuGroup: FC<IMenuGroupProps> = ({ namespace = '', ...rest }) => {
  const match = useMatch(namespace);
  const [isOpened, setIsOpened] = useState<boolean>(Boolean(match));

  return (
    <Menu.Item
      {...rest}
      active={isOpened}
      componentProps={{
        className: cs(styles.navGroup, { [styles.active]: isOpened }),
        onClick: () => setIsOpened(!isOpened),
      }}
    />
  );
};
