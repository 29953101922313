import { Field } from 'react-final-form';
import { Checkbox } from '@smart-kasa/ui';

export const CheckboxField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <Checkbox
        {...input}
        {...rest}
        {...props}
        checked={input.value}
        onChange={(value) => input.onChange(value)}
      />
    )}
  </Field>
);
