import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IInvitation, IInvitationSignup } from './types';

export const invitationsApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    createUserInvitation: builder.mutation<CoreResponse<IIdResponse>, Partial<IInvitation>>({
      query: (invitation) => ({
        url: '/iam/invitations',
        method: 'POST',
        body: { invitation },
      }),
    }),
    checkUserInvitation: builder.query<CoreResponse<IIdResponse>, string>({
      query: (token) => ({ url: `/iam/invitations/${token}` }),
    }),
    acceptUserInvitation: builder.mutation<CoreResponse<IIdResponse>, IInvitationSignup>({
      query: ({ token, ...user }) => ({
        url: `/iam/invitations/${token}/accept`,
        method: 'POST',
        body: { user },
      }),
    }),
  }),
});

export const {
  useCreateUserInvitationMutation,
  useCheckUserInvitationQuery,
  useAcceptUserInvitationMutation,
} = invitationsApi;
