import { Fragment } from 'react';
import { Row, Col, CardTitle } from '@smart-kasa/ui';

import { BluredView, InfoRow } from 'components';
import type { IDevice } from 'services/api/tms/devices/types';

export type DeviceInfoProps = {
  device: IDevice;
  children?: never;
};

export const DeviceInfo = ({ device }: DeviceInfoProps) => (
  <Fragment>
    <Row gutter={10}>
      <Col span={12}>
        <CardTitle>Про пристрій</CardTitle>

        <BluredView>
          <InfoRow name="Модель (ID)">LMY47V</InfoRow>
          <InfoRow name="Рівень заряду батареї">98%</InfoRow>
          <InfoRow name="Температура батареї">35.5</InfoRow>
          <InfoRow name="Заряджання">False</InfoRow>
          <InfoRow name="Обсяг вільної пам'яті">476.8 MB</InfoRow>
          <InfoRow name="Обсяг вільного сховища даних">4.5 GB</InfoRow>
          <InfoRow name="Низький обсяг пам'яті">False</InfoRow>
          <InfoRow name="Обсяг пам'яті">902.9 MB</InfoRow>
          <InfoRow name="Обсяг сховища даних">5.3 GB</InfoRow>
          <InfoRow name="Тип з'єднання (мережа)">cellular</InfoRow>
          <InfoRow name="Мова системи">uk</InfoRow>
          <InfoRow name="Країна">uk_UA</InfoRow>
          <InfoRow name="Часовий пояс">Europe/Kyiv</InfoRow>
        </BluredView>
      </Col>
      <Col span={12}>
        <CardTitle>Операційна система</CardTitle>

        <BluredView>
          <InfoRow name="Версія ядра">
            Linux version 3.10.49-g458357c-00196-gf86859f (jenkins@NB-ubuntu-001) (gcc version 4.8
            (GCC) ) #1 SMP PREEMPT Wed Apr 7 14:02:13 CST 2021
          </InfoRow>
          <InfoRow name="Назва">Android</InfoRow>
          <InfoRow name="Версія">5.1.1(WPOS3X_V01.00_2104071341_international_user)</InfoRow>
        </BluredView>
      </Col>
    </Row>
  </Fragment>
);
