import { Routes, Route } from 'react-router-dom';

import { EventList } from './routes/EventList';
import { EventShow } from './routes/EventShow';

export const Events = () => (
  <Routes>
    <Route index element={<EventList />} />
    <Route path="/:id" element={<EventShow />} />
  </Routes>
);
