import { AsyncSelect, AsyncSelectProps } from '@smart-kasa/ui';
import Field from '../Field/Field';

export type AsyncSelectFieldProps = Omit<AsyncSelectProps, 'value'> & {
  name: string;
  value?: any;
};

export const AsyncSelectField = ({ name, ...props }: AsyncSelectFieldProps) => (
  <Field name={name}>
    {({ input, ...rest }) => <AsyncSelect {...input} {...rest} {...props} />}
  </Field>
);
