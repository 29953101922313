import { PieChart as Chart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { PieLabel } from './PieLabel';

export type PieChartProps = {
  width?: string | number;
  height?: string | number;
  colors?: Array<string>;
  data?: any[];
  dataKey?: string;
};

export const PieChart = ({
  width,
  height = 300,
  colors = ['#f2b711', '#444674', '#fe8257', '#6d2ad8', '#57b7da'],
  data = [],
  dataKey = 'value',
  ...rest
}: PieChartProps) => (
  <ResponsiveContainer width={width} height={height}>
    <Chart {...rest}>
      <Pie data={data} labelLine={false} label={PieLabel} fill="#8884d8" dataKey={dataKey}>
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip
        wrapperStyle={{ outline: 'none' }}
        contentStyle={{
          fontSize: 12,
          border: '1px solid rgb(219, 214, 225)',
          borderRadius: 6,
          boxShadow: '-3px 4px 8px -8px rgba(34, 60, 80, .2)',
        }}
      />
      <Legend layout="horizontal" align="center" iconType="circle" />
    </Chart>
  </ResponsiveContainer>
);
