export enum TaskState {
  PENDING = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export enum TaskType {
  PUSH_APP = 0,
  PUSH_INSTRUCTION = 1,
  SEND_MESSAGE = 2,
  ALLOCATE = 3,
}
