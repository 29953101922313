import { FC, Fragment } from 'react';
import { FormattedDate, CustomFormatConfig } from 'react-intl';

export type DateViewProps = Intl.DateTimeFormatOptions &
  CustomFormatConfig & {
    value: string | number | Date | undefined;
    children?(formattedDate: string): React.ReactElement | null;
  };

export const DateView: FC<DateViewProps> = ({ value, ...rest }) => (
  <Fragment>{value ? <FormattedDate {...rest} value={value} /> : '-'}</Fragment>
);

DateView.defaultProps = {
  dateStyle: 'short',
  timeStyle: 'short',
};
