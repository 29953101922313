import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import type { ISignup } from 'services/api/tms/auth/types';
import {
  useAcceptUserInvitationMutation,
  useCheckUserInvitationQuery,
} from 'services/api/tms/invitations/api';

import { SignupForm } from '../../components/SignupForm';
import { SignupFormErrorSchema } from '../../components/SignupForm/SignupFormSchema';

export const Registration = () => {
  const { token = '' } = useParams();
  const navigate = useNavigate();
  const { data: invitation, isLoading } = useCheckUserInvitationQuery(token);
  const [signup] = useAcceptUserInvitationMutation();

  const handleSubmit = async (values: ISignup) => {
    return await signup({ token, ...values })
      .unwrap()
      .then(() => navigate(ROUTES.authLogin()))
      .catch((err) => SignupFormErrorSchema.get(err));
  };

  if (isLoading) return <Preloader />;

  return (
    <Fragment>
      <PageHeader>Реєстрація</PageHeader>

      {invitation ? (
        <SignupForm defaultValues={{}} onSubmit={handleSubmit} />
      ) : (
        <Alert icon="info" type="danger">
          На жаль, посилання на реєстрацію вже неактивне. Просимо вас звернутися до адміністратора
          або відповідальної особи, щоб отримати актуальну інформацію.
        </Alert>
      )}
    </Fragment>
  );
};
