import { Fragment, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, ButtonText, FormElement, InlineSpace, PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import {
  useRequestPasswordChangeMutation,
  useResetPasswordMutation,
} from 'services/api/tms/auth/api';

import {
  PasswordRecoveryCodeSchema,
  PasswordRecoveryPasswordSchema,
  PasswordRecoveryPhoneSchema,
} from './PasswordRecoverySchema';

enum PASSWORD_RECOVERY_STEP {
  email,
  confirmEmail,
  password,
}

const SCHEMA_BY_STEP = {
  [PASSWORD_RECOVERY_STEP.email]: PasswordRecoveryPhoneSchema,
  [PASSWORD_RECOVERY_STEP.confirmEmail]: PasswordRecoveryCodeSchema,
  [PASSWORD_RECOVERY_STEP.password]: PasswordRecoveryPasswordSchema,
};

export type PasswordRecoveryForm = {
  email: string;
  code: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<PASSWORD_RECOVERY_STEP>(PASSWORD_RECOVERY_STEP.email);

  const [requestPasswordChange] = useRequestPasswordChangeMutation();
  const [resetPassword] = useResetPasswordMutation();

  const [publicToken, setPublicToken] = useState<string>('');

  const sendConfirmation = useCallback(
    async (email: string) => {
      try {
        const { data } = await requestPasswordChange(email).unwrap();

        setPublicToken(data.publicToken);
        setStep(PASSWORD_RECOVERY_STEP.confirmEmail);
      } catch (err) {
        return {
          email:
            'Такий номер не зареєстровано. Перевірте коректніть вводу або зареєструйте особистий кабінет.',
        };
      }
    },
    [requestPasswordChange]
  );

  const handleResetPassword = useCallback(
    async ({ email, ...fd }: PasswordRecoveryForm) => {
      try {
        await resetPassword({ token: publicToken, ...fd });

        navigate(ROUTES.authLogin());
      } catch (err) {
        return {
          newPassword:
            'Перевірте коректність вводу або зареєструйте особистий кабінет просто зараз.',
        };
      }
    },
    [publicToken, resetPassword, navigate]
  );

  const handleSubmit = useCallback(
    async (fd: PasswordRecoveryForm) => {
      if (step === PASSWORD_RECOVERY_STEP.email) {
        try {
          return await sendConfirmation(fd.email);
        } catch (err) {
          return {
            email:
              'Номер не знайдено. Перевірте коректність вводу або зареєструйте особистий кабінет просто зараз.',
          };
        }
      }

      if (step === PASSWORD_RECOVERY_STEP.confirmEmail) {
        setStep(PASSWORD_RECOVERY_STEP.password);
      }

      if (step === PASSWORD_RECOVERY_STEP.password) {
        return await handleResetPassword(fd);
      }
    },
    [step, sendConfirmation, handleResetPassword]
  );

  return (
    <Fragment>
      <PageHeader>Відновлення паролю</PageHeader>
      <Form<PasswordRecoveryForm>
        initialValues={{}}
        validate={yupResolver(SCHEMA_BY_STEP[step])}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {step === PASSWORD_RECOVERY_STEP.email && (
              <Field.Input name="email" label="Введіть адресу електронної пошти" />
            )}

            {step === PASSWORD_RECOVERY_STEP.confirmEmail && (
              <Field.Input
                label={`Введіть код, відправлений на ${values.email}`}
                name="code"
                placeholder="XXXXXX"
              />
            )}

            {step === PASSWORD_RECOVERY_STEP.password && (
              <Fragment>
                <Field.Password label="Введіть Ваш пароль" name="newPassword" />
                <Field.Password label="Повторіть пароль" name="newPasswordConfirmation" />
              </Fragment>
            )}

            <FormElement alignItems="center">
              <Button type="submit" color="orange" width="full">
                <FormattedMessage id="auth.buttons.recover" />
              </Button>
              <InlineSpace direction="vertical" />
              <ButtonText onClick={() => navigate(ROUTES.authLogin())}>
                <FormattedMessage id="auth.navigation.login" />
              </ButtonText>
            </FormElement>
          </form>
        )}
      </Form>
    </Fragment>
  );
};
