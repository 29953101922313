import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { IInputProps, Input } from '@smart-kasa/ui';

import styles from './Datepicker.module.scss';

const DATE_FORMAT = 'd MMMM yyyy';

export type DatepickerProps = ReactDatePickerProps & {
  inputProps?: IInputProps;
};

export const Datepicker = ({ dateFormat, inputProps, ...props }: DatepickerProps) => (
  <DatePicker
    dateFormat={dateFormat || DATE_FORMAT}
    showMonthDropdown
    showYearDropdown
    customInput={<Input className={styles.input} {...inputProps} />}
    popperPlacement="top-end"
    popperModifiers={{
      preventOverflow: { enabled: true },
    }}
    {...props}
  />
);
