import { Routes, Route } from 'react-router-dom';

import { BankList } from './routes/BankList';
import { BankCreate } from './routes/BankCreate';
import { BankDashboard } from './routes/BankDashboard';
import { BankShow } from './routes/BankShow';
import { BankEdit } from './routes/BankEdit';
import { BankDelete } from './routes/BankDelete';
import { Gateways } from './routes/Gateways';

export const Banks = () => (
  <Routes>
    <Route index element={<BankList />} />
    <Route path="/new" element={<BankCreate />} />
    <Route path=":bankId" element={<BankShow />}>
      <Route index element={<BankDashboard />} />
      <Route path="gateways/*" element={<Gateways />} />
      <Route path="terminals" element={null} />
      <Route path="edit" element={<BankEdit />} />
      <Route path="delete" element={<BankDelete />} />
    </Route>
  </Routes>
);
