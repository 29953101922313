import * as yup from 'yup';
import { schemaError } from 'utils/schemaError';
import { schemaPassword } from 'utils/sharedSchemas';

export const SignupFormSchema = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  password: schemaPassword,
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Пароль не співпадає'),
});

export const SignupFormErrorSchema = schemaError({
  default: 'Невірний номер телефону чи пароль',
});
