import * as yup from 'yup';

export const GatewayFormSchema = yup.object().shape({
  environment: yup
    .string()
    .matches(
      /^[a-z](-?[a-z])*$/,
      'Дозволені лише малі літери латинського алфавіту (a-z) та символ -'
    ),
  host: yup.string().required(),
  port: yup.number().required(),
  connectionTimeout: yup.number(),
  requestTimeout: yup.number(),
});
