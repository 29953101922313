import { Routes, Route } from 'react-router-dom';

import { AppList } from './routes/AppList';
import { AppCreate } from './routes/AppCreate';
import { AppShow } from './routes/AppShow';
import { AppEdit } from './routes/AppEdit';

export const Store = () => (
  <Routes>
    <Route index element={<AppList />} />
    <Route path="/new" element={<AppCreate />} />
    <Route path="/:id" element={<AppShow />} />
    <Route path="/:id/edit" element={<AppEdit />} />
  </Routes>
);
