import { Input, PriceInput, NumberInput, Textarea, SpinnerInput, TagsInput } from '@smart-kasa/ui';

import { PasswordInput } from 'components/PasswordInput';
import { ButtonGroup } from 'components/ButtonGroup';

import FormField from './Field';
import { withFinalForm } from './/withFinalForm';
import { SelectField } from '../Form/SelectField';
import { AsyncSelectField } from '../Form/AsyncSelectField';
import { DatepickerField } from '../Form/DatepickerField';
import { UploaderField } from '../Form/UploaderField';
import { SwitchField } from '../Form/SwitchField';
import { RadioSwitchField } from '../Form/RadioSwitchField';
import { CheckboxField } from '../Form/CheckboxField';
import { FileField } from '../Form/FileField';

export type FieldType = any;

export const Field = FormField as FieldType;

Field.Input = withFinalForm(Input);
Field.TextArea = withFinalForm(Textarea);
Field.Checkbox = withFinalForm(CheckboxField);
Field.Password = withFinalForm(PasswordInput);
Field.Price = withFinalForm(PriceInput);
Field.Tags = withFinalForm(TagsInput);
Field.Spinner = withFinalForm(SpinnerInput);
Field.Number = withFinalForm(NumberInput);
Field.Select = withFinalForm(SelectField);
Field.AsyncSelect = withFinalForm(AsyncSelectField);
Field.Datepicker = withFinalForm(DatepickerField);
Field.Uploader = withFinalForm(UploaderField);
Field.Switch = withFinalForm(SwitchField);
Field.RadioSwitch = withFinalForm(RadioSwitchField);
Field.File = withFinalForm(FileField);
Field.ButtonGroup = withFinalForm(ButtonGroup);
