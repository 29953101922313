import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, ButtonIcon, IDataTableColumn, Icon, PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { AttributeTable, DateView, Picture } from 'components';
import { useBanks } from 'features/banks/hooks/useBanks';
import type { IBank } from 'services/api/tms/banks/types';

export const BankList = () => {
  const navigate = useNavigate();
  const { banks, isLoading } = useBanks();

  const columns: IDataTableColumn<IBank>[] = [
    {
      key: 'logo',
      width: 70,
      render: (logo) => <Picture src={logo} icon="bank" size="M" fill="grey" />,
    },
    {
      key: 'name',
      title: 'Назва',
    },
    {
      key: 'registrationNumber',
      title: 'ЄДРПОУ',
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.bankCreate())}>
            Додати організацію
          </Button>
        }
      >
        Фінансові організації
      </PageHeader>

      <AttributeTable
        data={banks}
        isLoading={isLoading}
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.bankShow(item.id))}
      />

      <Outlet />
    </Fragment>
  );
};
