import { tmsApi } from '../api';
import type { CoreResponse } from 'types';
import type { IRole } from './types';

export const rolesApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<CoreResponse<IRole[]>, void>({
      query: () => ({ url: '/iam/roles' }),
    }),
  }),
});

export const { useGetRolesQuery } = rolesApi;
