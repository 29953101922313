import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateVendorMutation } from 'services/api/tms/vendors/api';

import { VendorForm } from '../../components/VendorForm';

export const VendorCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateVendorMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareVendors());
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareVendors()));
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Створення постачальника</PageHeader>

      <VendorForm defaultValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
