import { Component, ComponentType, ErrorInfo, Fragment, ReactNode } from 'react';

import { BlankView } from '../BlankView';

interface IErrorBoundary {
  children: ReactNode;
  errorComponent?: ComponentType;
}

interface IErrorBoundaryState {
  isError: boolean;
}

const ErrorComponentDefault = () => <BlankView>Хьюстон, у нас проблема</BlankView>;

export class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
  state = {
    isError: false,
  };

  componentDidCatch(error: Error, _errorInfo: ErrorInfo): void {
    this.setState({ isError: true });

    console.error(error);
  }

  render() {
    const { isError } = this.state;
    const { errorComponent: ErrorComponent = ErrorComponentDefault, children } = this.props;

    return <Fragment>{isError ? <ErrorComponent /> : children}</Fragment>;
  }
}
