import { Routes, Route } from 'react-router-dom';

import { GooglePlacesContextProvider } from 'contexts/GooglePlacesContext';

import { TerminalList } from './routes/TerminalList';
import { TerminalCreate } from './routes/TerminalCreate';
import { TerminalShow } from './routes/TerminalShow';

export const Terminals = () => (
  <GooglePlacesContextProvider>
    <Routes>
      <Route index element={<TerminalList />} />
      <Route path="/new" element={<TerminalCreate />} />
      <Route path=":id" element={<TerminalShow />} />
    </Routes>
  </GooglePlacesContextProvider>
);
