import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Alert, Button, FormElement, InlineSpace, Paragraph, Strong } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { fromEnumToOptions } from 'utils/fromEnum';
import type { ICustomer } from 'services/api/tms/customers/types';
import { BusinessEntityType } from 'services/api/tms/customers/enums';

import { CustomerFormSchema } from './CustomerFormSchema';

interface ICustomerForm {
  defaultValues: Partial<ICustomer>;
  onSubmit: (fd) => void;
}

export const CustomerForm: FC<ICustomerForm> = ({ defaultValues, onSubmit }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <Form<any>
      validate={yupResolver(CustomerFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Alert>
            <Paragraph>Будь ласка, уважно заповніть коректні дані підприємства.</Paragraph>
            <Strong>
              Вказані дані будуть використані на чеках терміналу після підключення послуг
              еквайрингу.
            </Strong>
          </Alert>

          <Field.Select
            name="businessEntityType"
            placeholder="Оберіть зі списку"
            label="Форма власності"
            options={fromEnumToOptions(BusinessEntityType)}
            required
          />

          <Field.Input name="registrationNumber" label="ІПН/ЄДРПОУ" required />
          <Field.Input name="organizationName" label="Назва торговця/організації" required />

          <InlineSpace direction="vertical" size={30} />

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="dark" onClick={() => navigate(-1)}>
              Скасувати
            </Button>
            <Button width="narrow" color="dark" type="submit">
              {formatMessage({ id: 'buttons.save' })}
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
