import { createSelector } from '@reduxjs/toolkit';

import { banksApi } from 'services/api/tms/banks/api';

export const selectAllBanks = createSelector(
  banksApi.endpoints.getBanks.select(),
  (result) => result?.data?.data ?? []
);

export const selectBankById = createSelector(
  [selectAllBanks, (_state, bankId) => bankId],
  (banks, bankId) => banks?.find((vendor) => vendor.id === bankId)
);
