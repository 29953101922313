import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import type { IApplicationPackage } from 'services/api/tms/apps/types';
import { AppCard } from 'features/store/components/AppCard';

export const AppList = () => {
  const navigate = useNavigate();
  const apps: IApplicationPackage[] = [
    {
      id: '27f4e5bc-8b17-401b-86b5-a8ecdaa404a8',
      name: 'Смарт Каса',
      packageName: 'com.smart.kasa',
      description:
        'Додаток «Смарт Каса» можна встановити на телефон на базі Android. Завдяки пРРО, активованому на власному гаджеті, підприємець отримує в єдиному комплексі торгову касу «СмартКаса», програмний РРО.',
      state: 0,
      logo: {
        id: 'b02ede4d-2241-448f-b72a-c969c2aa9652',
        storage: 'store',
        metadata: {
          filename: 'Смарт Каса',
          mimeType: '',
          size: 55233,
        },
      },
      applicationType: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: '0cf097c5-e922-494a-b906-0d77aab3b68a',
      name: 'Payment Application',
      packageName: 'pos.test',
      description: 'Платіжний застосунок',
      state: 0,
      applicationType: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.appStoreCreate())}>
            Новий додаток
          </Button>
        }
      >
        Маркет додатків
      </PageHeader>

      {apps.map((app) => (
        <AppCard key={app.id} app={app} onOpen={() => navigate(ROUTES.appStoreShow(app.id))} />
      ))}
    </Fragment>
  );
};
