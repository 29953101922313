// const task = {
//   id: 1,
//   name: '1492375461238423',
//   state: 0,
//   taskType: 1,
//   userId: 9,
//   user: {
//     id: 9,
//     state: 1,
//     name: 'Test',
//     email: 'cto@smartkasa.ua',
//     roleId: 1,
//     createdAt: new Date(),
//     updatedAt: new Date(),
//   },
//   stats: {
//     successCount: 16,
//     failureCount: 3,
//     totalCount: 20,
//   },
//   completedAt: new Date(),
//   createdAt: new Date(),
//   updatedAt: new Date(),
// };

export const TaskShow = () => <>Task Show</>;
