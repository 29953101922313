import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button } from '@smart-kasa/ui';

import { Field } from 'components';
import { fromEnumToOptions } from 'utils/fromEnum';
import { DeviceState } from 'services/api/tms/devices/enums';

import { useDeviceVendors } from 'features/hardware/routes/Vendors/hooks/useDeviceVendors';
import { useDeviceModels } from 'features/hardware/routes/Models/hooks/useDeviceModels';

export interface IDeviceFilterForm {
  defaultValues: any;
  onSubmit: (values) => void;
}

export const DeviceFilterForm: React.FC<IDeviceFilterForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { vendors } = useDeviceVendors();
  const { models } = useDeviceModels();

  return (
    <Form<any> initialValues={defaultValues} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Field.Select
            name="state"
            placeholder="Оберіть статус"
            label={formatMessage({ id: 'hardware.device.state' })}
            options={fromEnumToOptions(DeviceState)}
          />
          <Field.Select
            name="vendorId"
            placeholder="Оберіть постачальника"
            label="Постачальник"
            isClearable
            value={Number(values.vendorId)}
            options={vendors}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
          />

          <Field.Select
            name="modelId"
            placeholder="Оберіть модель"
            label="Модель"
            isClearable
            value={Number(values.modelId)}
            options={models}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
          />

          <Button type="submit" color="dark" width="full">
            {formatMessage({ id: 'buttons.filter' })}
          </Button>
        </form>
      )}
    </Form>
  );
};
