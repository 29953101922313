import { FC, useCallback } from 'react';
import { FileUploader, IFileUploader } from '@smart-kasa/ui';

import { getFileUrl } from 'utils/getFileUrl';
import { useUploadMutation } from 'services/api/tms/assets/api';
import type { AssetFile } from 'services/api/tms/assets/types';

export type IUploaderProps = Omit<
  IFileUploader,
  'value' | 'previewList' | 'onChange' | 'onRemove'
> & {
  value: AssetFile | AssetFile[] | undefined;
  onChange: (files: AssetFile[]) => void;
  onRemove?: (file: AssetFile) => void;
};

export const Uploader: FC<IUploaderProps> = ({ value, onChange, onRemove, ...rest }) => {
  const [upload, { isLoading }] = useUploadMutation();

  const load = useCallback(
    async (file: File) => {
      const asset = await upload(file).unwrap();

      return asset;
    },
    [upload]
  );

  const handleRemove = useCallback(
    (index) => {
      const file = Array.isArray(value) ? value.find((_, i) => index === i) : value;

      file && onRemove && onRemove(file);
    },
    [value, onRemove]
  );

  const handleUpload = useCallback(
    async (files?: File[]) => {
      if (!files) return;

      const assets = await Promise.all(files.map(load));

      onChange && onChange(assets);
    },
    [load, onChange]
  );

  const previewList = Array.isArray(value) ? value.map((file) => getFileUrl(file)) : [null];
  const preview = value && !Array.isArray(value) && getFileUrl(value);

  return (
    <FileUploader
      {...rest}
      value={value as any}
      isLoading={isLoading}
      previewList={previewList}
      preview={preview}
      onChange={handleUpload}
      onRemove={handleRemove}
    />
  );
};
