import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, FormElement } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IDeviceVendor } from 'services/api/tms/vendors/types';

import { VendorFormSchema } from './VendorFormSchema';

export interface IVendorForm {
  defaultValues: Partial<IDeviceVendor>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const VendorForm: FC<IVendorForm> = ({ defaultValues, onCancel, onSubmit }) => (
  <Form<any>
    validate={yupResolver(VendorFormSchema)}
    initialValues={defaultValues}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field.Input name="name" label="Назва" required />
        <Field.Input name="address" label="Адреса" />
        <Field.Input name="email" label="Email" />

        <FormElement direction="row" justifyContent="flex-end">
          <Button variant="text" color="dark" onClick={onCancel}>
            Скасувати
          </Button>
          <Button width="narrow" color="dark" type="submit">
            Зберегти
          </Button>
        </FormElement>
      </form>
    )}
  </Form>
);
