import { createSelector } from '@reduxjs/toolkit';

import { modelsApi } from 'services/api/tms/models/api';

export const selectAllModels = createSelector(
  modelsApi.endpoints.getDeviceModels.select(),
  (result) => result?.data?.data ?? []
);

export const selectModelById = createSelector(
  [selectAllModels, (_state, modelId) => modelId],
  (models, modelId) => models?.find((model) => model.id === modelId)
);
