import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarItem, Hint, InlineSpace, Strong } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { DateView, Picture } from 'components';
import type { IDevice } from 'services/api/tms/devices/types';
import type { IPosTerminal } from 'services/api/tms/terminals/types';

import { SearchableItem } from './SearchableItem';

export type SearchResultsProps = {
  devices?: IDevice[];
  terminals?: IPosTerminal[];
  onSelect?: () => void;
};

export const SearchResults: FC<SearchResultsProps> = ({
  terminals = [],
  devices = [],
  onSelect,
}) => {
  const navigate = useNavigate();

  const handleSelect = (url) => {
    navigate(url, { state: { modal: true } });
    onSelect && onSelect();
  };

  return (
    <Fragment>
      {devices.map((device) => (
        <SearchableItem
          key={device.id}
          onClick={() => handleSelect(ROUTES.hardwareDeviceShow(device.id))}
        >
          <AvatarItem
            avatar={<Picture src={device.model?.photo} icon="smartKasa" size="M" fill="grey" />}
            primaryText={device.serialNumber}
            secondaryText={<DateView value={device.lastOnlineAt} />}
          />
          <InlineSpace size={5} direction="vertical" />
        </SearchableItem>
      ))}

      {terminals.map((terminal) => (
        <SearchableItem
          key={terminal.id}
          onClick={() => handleSelect(ROUTES.terminalShow(terminal.id))}
        >
          <Strong>
            {terminal.tid} / {terminal.merchant.mid}
          </Strong>
          <Hint margin={0}>{terminal.merchant.name}</Hint>
        </SearchableItem>
      ))}
    </Fragment>
  );
};
