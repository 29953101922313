import { Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Icon, PageHeader, Preloader, InlineSpace, Row, Col, Tag } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { BackRouteLink, DateView, Description, Panel } from 'components';
import { enumValueToKey } from 'utils/fromEnum';
import { useFindCustomerQuery, useDeleteCustomerMutation } from 'services/api/tms/customers/api';
import { CustomerState, BusinessEntityType } from 'services/api/tms/customers/enums';

import { CustomerMerchants } from 'features/customers/components/CustomerMerchants';

export const CustomerShow = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { id } = useParams();
  const { customer, isLoading } = useFindCustomerQuery(String(id), {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, customer: response?.data }),
  });
  const [destroy] = useDeleteCustomerMutation();

  const handleDelete = async (id: string) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити картку клієта?');

    if (isConfirmed)
      destroy(id)
        .unwrap()
        .then(() => navigate(ROUTES.customers()));
  };

  if (!customer || isLoading) return <Preloader />;

  return (
    <Fragment>
      <BackRouteLink to={ROUTES.customers()}>Повернутись</BackRouteLink>
      <PageHeader
        actions={
          <Fragment>
            <Button
              color="dark"
              rightIcon={<Icon name="edit" />}
              onClick={() => navigate(ROUTES.customerEdit(id))}
            >
              Редагувати
            </Button>
            <InlineSpace />
            <Button
              variant="outlined"
              color="danger"
              rightIcon={<Icon name="trash" />}
              onClick={() => handleDelete(customer.id)}
            >
              Видалити
            </Button>
          </Fragment>
        }
      >
        {customer.organizationName}
      </PageHeader>

      <Panel>
        <Row gutter={10}>
          <Col span={6}>
            <Description label="Статус:">
              <Tag color="violet">{enumValueToKey(CustomerState, customer.state)}</Tag>
            </Description>
          </Col>
          <Col span={6}>
            <Description label="ІПН/ЄДРПОУ:">{customer.registrationNumber}</Description>
          </Col>
          <Col span={6}>
            <Description label="Форма власності:">
              {enumValueToKey(BusinessEntityType, customer.businessEntityType)}
            </Description>
          </Col>
          <Col span={6}>
            <Description label="Дата створення:">
              <DateView value={customer.createdAt} />
            </Description>
          </Col>
        </Row>
      </Panel>

      <InlineSpace direction="vertical" size={30} />

      <CustomerMerchants customer={customer} />
    </Fragment>
  );
};
