import { Routes, Route } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { UserList } from './routes/UserList';
import { UserCreate } from './routes/UserCreate';
import { UserEdit } from './routes/UserEdit';

export const Users = () => (
  <Routes>
    <Route element={<UserList />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="/new" element={<UserCreate />} />
        <Route path="/:id/edit" element={<UserEdit />} />
      </Route>
    </Route>
  </Routes>
);
