import { useAppSelector } from 'hooks/useAppStore';

import { useGetDeviceModelsQuery } from 'services/api/tms/models/api';
import type { IDeviceModel } from 'services/api/tms/models/types';

import { selectAllModels } from '../slice';

type UseDeviceModelsResult = {
  models: IDeviceModel[];
  isLoading: boolean;
  refresh: () => void;
};

export const useDeviceModels = (): UseDeviceModelsResult => {
  const { refetch, isFetching } = useGetDeviceModelsQuery();
  const models = useAppSelector((state) => selectAllModels(state));

  return { models, refresh: refetch, isLoading: isFetching };
};
