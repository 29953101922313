type Enum = { [s: number]: string };

interface Option<T> {
  label: keyof T;
  value: T[keyof T];
}

export const isEnumKey = <T extends Enum>(source: T, key: unknown): key is keyof T =>
  Number.isInteger(source[key as keyof T]);

export const enumValueToKey = <T extends Enum>(source: T, value: T[keyof T]): keyof T | undefined =>
  (source as any)[value];

export const enumToKeys = <T extends Enum>(source: T): (keyof T)[] =>
  Object.keys(source).filter((key: keyof T | any) => isEnumKey(source, key)) as (keyof T)[];

export const enumToValues = <T extends Enum>(source: T): T[keyof T][] =>
  enumToKeys(source).map((key: keyof T) => source[key]);

export const enumToEntries = <T extends Enum>(source: T): [keyof T, T[keyof T]][] =>
  enumToKeys(source).map((key: keyof T) => [key, (source as any)[key] as T[keyof T]]);

export const fromEnum = <T extends Enum, C>(
  source: T,
  projection: (item: [keyof T, T[keyof T]], index: number, array: [keyof T, T[keyof T]][]) => C,
  skip?: (value: [keyof T, T[keyof T]], index: number, array: [keyof T, T[keyof T]][]) => boolean
) => {
  let entries = enumToEntries(source);

  if (skip) entries = entries.filter(skip);

  return entries.map(projection);
};

export const fromEnumToOptions = <T extends Enum>(source: T): Option<T>[] =>
  fromEnum(source, ([label, value]: [keyof T, T[keyof T]]) => ({
    label,
    value,
  }));
