import { useContext } from 'react';
import cs from 'classnames';
import { AvatarItem, DropDown, Strong } from '@smart-kasa/ui';

import { Picture, Skeleton } from 'components';
import { SessionContext } from 'contexts/SessionContext';
import { useProfile } from 'features/profile/hooks/useProfile';

import styles from './Account.module.scss';

export const Account = () => {
  const { logout } = useContext(SessionContext);
  const { profile, isLoading } = useProfile();

  if (isLoading)
    return (
      <AvatarItem
        avatar={<Skeleton className={cs(styles.loader, styles.thumb)} width={40} height={35} />}
        primaryText={<Skeleton className={styles.name} width={140} height={10} />}
        secondaryText={
          <Skeleton className={cs(styles.loader, styles.email)} width={94} height={8} />
        }
      />
    );

  return (
    <DropDown type="arrow" className={styles.user}>
      <DropDown.Toggle>
        <AvatarItem
          avatar={
            <Picture
              src={profile?.photo}
              icon="support"
              fill="grey"
              size="M"
              className={styles.icon}
            />
          }
          primaryText={<Strong className={styles.name}>{profile?.name}</Strong>}
          secondaryText={<span className={styles.email}>{profile?.email}</span>}
        />
      </DropDown.Toggle>
      <DropDown.Menu className={styles.menu}>
        <DropDown.Item icon="logout" onClick={logout}>
          Вийти з системи
        </DropDown.Item>
      </DropDown.Menu>
    </DropDown>
  );
};
