import { tmsApi } from '../api';
import type { CoreResponse, Pagination } from 'types';
import type { IPosTerminal, PosTerminalFilterQuery } from './types';

export const posTerminalsApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPosTerminals: builder.query<
      CoreResponse<IPosTerminal[]>,
      (Pagination<IPosTerminal> & PosTerminalFilterQuery) | void
    >({
      query: (params) => ({ url: '/terminals', params: params || {} }),
    }),
    findPosTerminal: builder.query<CoreResponse<IPosTerminal>, string>({
      query: (id) => ({ url: `/terminals/${id}` }),
    }),
  }),
});

export const { useGetPosTerminalsQuery, useLazyGetPosTerminalsQuery, useFindPosTerminalQuery } =
  posTerminalsApi;
