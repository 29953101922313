import React, { ReactNode } from 'react';

import { RootState } from 'redux/store';
import { resetStateAction } from 'redux/actions/resetState';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { useSignInMutation } from 'services/api/tms/auth/api';
import { signOut } from 'features/auth/slice';

export interface ISessionContext {
  session?: string;
  isLogged: boolean;
  login: any;
  logout: () => void;
}

export const SessionContext = React.createContext<ISessionContext>({
  session: undefined,
  isLogged: false,
  login: () => {},
  logout: () => void 0,
});

export const SessionContextProvider = ({ children }: { children: ReactNode }) => {
  const [signIn] = useSignInMutation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state: RootState) => state.auth.accessToken);

  const logout = () => {
    dispatch(signOut());
    dispatch(resetStateAction());
  };

  return (
    <SessionContext.Provider
      value={{
        session: accessToken,
        isLogged: !!accessToken,
        login: signIn,
        logout: logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
