import { tmsApi } from '../api';
import type { CoreResponse } from 'types';
import type { IMarker, MarkerFilterQuery } from './types';

export const markersApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarkers: builder.query<CoreResponse<IMarker[]>, MarkerFilterQuery>({
      query: (params) => ({ url: '/markers', params }),
    }),
  }),
});

export const { useGetMarkersQuery } = markersApi;
