import { Route, Routes } from 'react-router-dom';

import { Tags } from './routes/Tags';
import { Vendors } from './routes/Vendors';
import { Models } from './routes/Models';
import { Devices } from './routes/Devices';

export const Hardware = () => (
  <Routes>
    <Route path="tags/*" element={<Tags />} />
    <Route path="vendors/*" element={<Vendors />} />
    <Route path="models/*" element={<Models />} />
    <Route path="devices/*" element={<Devices />} />
  </Routes>
);
