import { useAppSelector } from 'hooks/useAppStore';

import { useGetTagsQuery } from 'services/api/tms/tags/api';
import type { IDeviceTag } from 'services/api/tms/tags/types';

import { selectAllTags } from '../slice';

type UseDeviceTagsResult = {
  tags: IDeviceTag[];
  isLoading: boolean;
  refresh: () => void;
};

export const useDeviceTags = (): UseDeviceTagsResult => {
  const { refetch, isFetching } = useGetTagsQuery();
  const tags = useAppSelector((state) => selectAllTags(state));

  return { tags, refresh: refetch, isLoading: isFetching };
};
