import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IDevice } from 'services/api/tms/devices/types';

import { useDeviceVendors } from 'features/hardware/routes/Vendors/hooks/useDeviceVendors';
import { useDeviceTags } from 'features/hardware/routes/Tags/hooks/useDeviceTags';
import { DeviceFormSchema } from './DeviceFormSchema';

export interface IDeviceForm {
  defaultValues: Partial<IDevice>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const DeviceForm: FC<IDeviceForm> = ({ defaultValues, onCancel, onSubmit }) => {
  const { vendors } = useDeviceVendors();
  const { tags } = useDeviceTags();

  return (
    <Form<any>
      validate={yupResolver(DeviceFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values }) => {
        const vendor = vendors.find((vendor) => vendor.id === values.vendorId);

        return (
          <form onSubmit={handleSubmit}>
            <Row gutter={10}>
              <Col span={12}>
                <Field.Input name="serialNumber" label="Серійний номер" required />
                <Field.Select
                  name="vendorId"
                  placeholder="Оберіть постачальника"
                  label="Постачальник"
                  options={vendors}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  required
                />
              </Col>
              <Col span={12}>
                <Field.Input name="imei" label="IMEI" />
                <Field.Select
                  name="modelId"
                  placeholder="Оберіть модель"
                  label="Модель"
                  options={vendor?.models}
                  disabled={!vendor}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  required
                />
              </Col>
            </Row>

            <Field.Select
              name="tagIds"
              placeholder="Оберіть категорії"
              label="Категорії"
              isMulti
              options={tags}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
            />
            <Field.TextArea name="note" label="Примітка" />

            <FormElement direction="row" justifyContent="flex-end">
              <Button variant="text" color="dark" onClick={onCancel}>
                Скасувати
              </Button>
              <Button width="narrow" color="dark" type="submit">
                Зберегти
              </Button>
            </FormElement>
          </form>
        );
      }}
    </Form>
  );
};
