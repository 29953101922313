import { ReactNode } from 'react';

import styles from './Description.module.scss';
import { InlineSpace } from '@smart-kasa/ui';

export type DescriptionProps = {
  label: ReactNode;
  children?: ReactNode;
};

export const Description = ({ label, children }: DescriptionProps) => (
  <div className={styles.group}>
    <div className={styles.title}>{label}</div>
    <div className={styles.text}>{children}</div>
    <InlineSpace direction="vertical" size={5} />
  </div>
);
