import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AvatarItem,
  CardTitle,
  Col,
  InlineSpace,
  Preloader,
  Row,
  Strong,
  Tag,
  Title,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { Description, InlineRouteLink, Panel, Picture } from 'components';
import { useProfile } from 'features/profile/hooks/useProfile';

import styles from './ProfileShow.module.scss';

export const ProfileShow = () => {
  const { profile } = useProfile();

  if (!profile) return <Preloader />;

  return (
    <Fragment>
      <Row gutter={10}>
        <Col span={7}>
          <Panel>
            <CardTitle>Дані облікового запису</CardTitle>
            <Picture
              src={profile.photo}
              icon="support"
              fill="grey"
              size="XXL"
              className={styles.avatar}
            />
            <Description label="ПІБ:">
              <Title>{profile.name}</Title>
            </Description>
            <Description label="Email:">{profile.email}</Description>
            <Description label="Номер телефону:">+{profile.phoneNumber}</Description>
            <Description label="Права доступу:">{profile.role.name}</Description>
          </Panel>
        </Col>
        <Col span={17}>
          <Panel>
            <CardTitle>Налаштування</CardTitle>

            <AvatarItem
              avatar={<Picture icon="info" fill="violet" size="M" className={styles.icon} />}
              primaryText={
                <InlineRouteLink to={ROUTES.profileEdit()}>
                  <Strong>Редагувати дані облікового запису</Strong>
                </InlineRouteLink>
              }
              secondaryText="Внесення змін до даних вашого облікового запису, такі як ім'я та номер телефону"
            />

            <InlineSpace direction="vertical" size={20} />

            <AvatarItem
              avatar={<Picture icon="security" fill="violet" size="M" className={styles.icon} />}
              primaryText={
                <InlineRouteLink to={ROUTES.profilePassword()}>
                  <Strong>Пароль облікового запису</Strong>
                </InlineRouteLink>
              }
              secondaryText="Для забезпечення безпеки вашого облікового запису, ми рекомендуємо змінювати пароль регулярно"
            />

            <InlineSpace direction="vertical" size={20} />

            <AvatarItem
              avatar={<Picture icon="look" fill="violet" size="M" className={styles.icon} />}
              primaryText={
                <Strong>
                  Багатофакторна аутентифікація (MFA) <Tag color="orange">недоступно</Tag>
                </Strong>
              }
              secondaryText="Використовуйте безкоштовний додаток для аутентифікації при вході, навіть офлайн, який підтримується як на пристроях з Android, так і на iPhone"
            />

            <InlineSpace direction="vertical" size={20} />

            <AvatarItem
              avatar={<Picture icon="news" fill="violet" size="M" className={styles.icon} />}
              primaryText={
                <Strong>
                  Сповіщення <Tag color="orange">недоступно</Tag>
                </Strong>
              }
              secondaryText="Отримуйте останні новини та оновлення через наші сповіщення! Будьте завжди в курсі подій"
            />
          </Panel>
        </Col>
      </Row>

      <Outlet />
    </Fragment>
  );
};
