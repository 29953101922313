import React, { ReactNode } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export interface IToastContext {
  toast: any;
}

export const ToastContext = React.createContext<IToastContext>({ toast });

export const ToastContextProvider = ({ children }: { children: ReactNode }) => (
  <ToastContext.Provider value={{ toast }}>
    {children}

    <ToastContainer position="bottom-right" pauseOnFocusLoss={false} autoClose={1500} limit={3} />
  </ToastContext.Provider>
);
