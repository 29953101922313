import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { useCreateTagMutation } from 'services/api/tms/tags/api';

import { TagForm } from '../../components/TagForm';

export const TagCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateTagMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(-1));
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Створення категорії</PageHeader>

      <TagForm
        defaultValues={{ isVisible: true }}
        onCancel={handleDismiss}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
};
