import { tmsApi } from '../api';
import type { CoreResponse } from 'types';
import type { ISetting } from './types';

export const settingsApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Settings'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<CoreResponse<ISetting>, void>({
      query: () => ({ url: '/settings' }),
      providesTags: ['Settings'],
    }),
    update: builder.mutation<ISetting, Partial<ISetting>>({
      query: (data) => ({
        url: `/settings`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
});

export const { useGetAllQuery, useUpdateMutation } = settingsApi;
