import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  AvatarItem,
  Button,
  ButtonIcon,
  DropDown,
  Hint,
  IDataTableColumn,
  Icon,
  InlineSpace,
  PageHeader,
  Pagination,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import useConfirm from 'hooks/useConfirm';
import { AttributeTable, DateView, Picture } from 'components';
import {
  useGetUsersQuery,
  useActivateUserMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
} from 'services/api/tms/users/api';
import type { IUser } from 'services/api/tms/users/types';
import { UserStatus } from 'features/users/components/UserStatus';
import { UserState } from 'services/api/tms/users/enums';

export const UserList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { confirm } = useConfirm();
  const { onPageChange } = useQueryPagination();
  const { data: users, isLoading } = useGetUsersQuery(query);
  const [activate] = useActivateUserMutation();
  const [block] = useBlockUserMutation();
  const [destroy] = useDeleteUserMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити користувача?');

    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IUser>[] = [
    {
      key: 'id',
      title: 'ID',
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: 'ПІБ',
      render: (name, { photo, email }) => (
        <AvatarItem
          avatar={<Picture src={photo} icon="user" size="M" fill="grey" />}
          primaryText={name}
          secondaryText={email}
        />
      ),
    },
    {
      key: 'phoneNumber',
      title: 'Номер телефону',
    },
    {
      key: 'role',
      title: 'Права доступу',
      render: ({ name }) => name,
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <UserStatus state={state} />,
    },
    {
      key: 'currentSignInAt',
      title: 'Дата останнього заходу',
      render: (lastOnlineAt) => <DateView value={lastOnlineAt} />,
    },
    {
      key: 'id',
      render: (id, { state }) => (
        <Fragment>
          <ButtonIcon>
            <Icon size="large" name="eye" />
          </ButtonIcon>
          <InlineSpace />
          <DropDown>
            <Button rightIcon={<Icon name="arrowDownThin" size="small" />}>Дії</Button>
            <DropDown.Menu>
              {state === UserState.DISABLED && (
                <DropDown.Item icon="check" onClick={() => activate(id)}>
                  Активувати
                </DropDown.Item>
              )}
              {state === UserState.ACTIVATED && (
                <DropDown.Item icon="close" onClick={() => block(id)}>
                  Деактивувати
                </DropDown.Item>
              )}
              <DropDown.Item icon="trash" onClick={() => handleDelete(id)}>
                Видалити
              </DropDown.Item>
            </DropDown.Menu>
          </DropDown>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.userCreate())}>
            Додати нового користувача
          </Button>
        }
      >
        Список користувачів
      </PageHeader>

      <AttributeTable
        data={users?.data}
        isLoading={isLoading}
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.userEdit(item.id))}
      />

      <Pagination
        totalItems={users?.meta?.totalCount}
        currentPage={users?.meta?.currentPage}
        perPage={users?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <Outlet />
    </Fragment>
  );
};
