import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useFindUserQuery, useUpdateUserMutation } from 'services/api/tms/users/api';

import { UserForm } from '../../components/UserForm';

export const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user } = useFindUserQuery(Number(id));
  const [update] = useUpdateUserMutation();

  const handleDismiss = () => navigate(ROUTES.users());
  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(ROUTES.users()));
    },
    [update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування користувача</PageHeader>
      <UserForm defaultValues={user?.data || {}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
