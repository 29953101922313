import { FC } from 'react';
import cs from 'classnames';
import L from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';

import styles from './MapBox.module.scss';

export type PointColor =
  | 'transparent'
  | 'orange'
  | 'yellow'
  | 'blue'
  | 'cyan'
  | 'grey'
  | 'violet'
  | 'green';

export type PointProps = MarkerProps & {
  color?: PointColor;
  size?: number;
};

export const Point: FC<PointProps> = ({ color = 'transparent', size = 16, ...rest }) => {
  const icon = L.divIcon({
    className: cs(styles.marker, styles[color]),
    iconSize: [size, size],
  });

  return <Marker icon={icon} {...rest} />;
};
