import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IBank } from './types';

export const banksApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Bank'] }).injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query<CoreResponse<IBank[]>, void>({
      query: () => ({ url: '/banks' }),
      providesTags: ['Bank'],
    }),
    findBank: builder.query<CoreResponse<IBank>, string>({
      query: (id) => ({ url: `/banks/${id}` }),
      providesTags: ['Bank'],
    }),
    createBank: builder.mutation<CoreResponse<IBank>, Partial<IBank>>({
      query: (bank) => ({
        url: '/banks',
        method: 'POST',
        body: { bank },
      }),
      invalidatesTags: ['Bank'],
    }),
    updateBank: builder.mutation<CoreResponse<IBank>, Pick<IBank, 'id'> & Partial<IBank>>({
      query: ({ id, ...bank }) => ({
        url: `/banks/${id}`,
        method: 'PUT',
        body: { bank },
      }),
      invalidatesTags: ['Bank'],
    }),
    deleteBank: builder.mutation<IIdResponse, string>({
      query: (id) => ({ url: `/banks/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Bank'],
    }),
  }),
});

export const {
  useGetBanksQuery,
  useFindBankQuery,
  useCreateBankMutation,
  useUpdateBankMutation,
  useDeleteBankMutation,
} = banksApi;
