import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import reduce from 'lodash/reduce';

const clearObject = <T extends object>(query: T) =>
  reduce(
    query,
    (acc, value: any, key) => {
      if (Boolean(value) && value !== '0') {
        acc[key as keyof T] = value;
      }
      return acc;
    },
    {} as T
  );

export function useQuery<T = any>(): {
  query: T;
  push: (query: Partial<T>) => void;
} {
  const { search } = useLocation();
  const navigate = useNavigate();

  const setQuery = useCallback(
    (query: object) => {
      navigate('?' + qs.stringify(clearObject(query), { arrayFormat: 'bracket' }));
    },
    [navigate]
  );

  const query = useMemo(
    () => (search ? qs.parse(search, { arrayFormat: 'bracket' }) : {}),
    [search]
  );

  return {
    query: query as any as T,
    push: setQuery,
  };
}
