import { Tag } from '@smart-kasa/ui';

import { NetworkState } from 'services/api/tms/devices/enums';

const STATE_NAME: Record<NetworkState, { name: string; color: any }> = {
  [NetworkState.OFFLINE]: {
    name: 'Мережа відсутня',
    color: 'grey',
  },
  [NetworkState.AWAY]: {
    name: 'Нещодавно був в мережі',
    color: 'yellow',
  },
  [NetworkState.ONLINE]: {
    name: 'В мережі',
    color: 'green',
  },
};

export type DeviceOnlineStatusProps = {
  state: NetworkState;
  children?: never;
};

export const DeviceOnlineStatus = ({ state }: DeviceOnlineStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
