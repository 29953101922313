import { useState } from 'react';

type UseModalResult = {
  isShowing: boolean;
  showModal: () => void;
  hideModal: () => void;
  toggleModal: () => void;
};

const useModal = (): UseModalResult => {
  const [isShowing, setIsShowing] = useState(false);
  const toggleModal = () => setIsShowing(!isShowing);

  return {
    isShowing,
    showModal: () => setIsShowing(true),
    hideModal: () => setIsShowing(false),
    toggleModal,
  };
};

export default useModal;
