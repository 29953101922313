import { FC } from 'react';
import cs from 'classnames';
import { TileLayer, MapContainer, MapContainerProps } from 'react-leaflet';

import styles from './MapBox.module.scss';

export type MapBoxProps = MapContainerProps;

export const MapBox: FC<MapBoxProps> = ({ className, children, ...rest }) => (
  <MapContainer className={cs(styles.map, className)} {...rest}>
    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

    {children}
  </MapContainer>
);

MapBox.defaultProps = {
  zoom: 10,
};
