import { Tag } from '@smart-kasa/ui';

import { TaskState } from 'services/api/tms/tasks/enums';

const STATE_NAME: Record<TaskState, { name: string; color: any }> = {
  [TaskState.PENDING]: {
    name: 'Очікує виконання',
    color: 'grey',
  },
  [TaskState.IN_PROGRESS]: {
    name: 'Виконується',
    color: 'violet',
  },
  [TaskState.COMPLETED]: {
    name: 'Виконано',
    color: 'green',
  },
};

export type TaskStatusProps = {
  state: TaskState;
  children?: never;
};

export const TaskStatus = ({ state }: TaskStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
