import { FC } from 'react';
import { Alert } from '@smart-kasa/ui';

import { MapBox, CirclePoint } from 'components';

export type DeviceLocationProps = {
  lat: number;
  lng: number;
};

export const DeviceLocation: FC<DeviceLocationProps> = ({ lat, lng }) => {
  if (!lat || !lng)
    return (
      <Alert icon="location">
        На жаль, нам не вдалося отримати геодані, ймовірно через те, що пристрій, можливо, не був
        активований або не підключений до мережі. Будь ласка, спробуйте синхронізувати дані або
        перевірте відображення пізніше.
      </Alert>
    );

  return (
    <MapBox center={[lat, lng]}>
      <CirclePoint position={[lat, lng]} />
    </MapBox>
  );
};
