import { useState } from 'react';

export const usePagination = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const onPageChange = ({ page, perPage }) => {
    setPage(page);
    setPerPage(perPage);
  };

  return { page, perPage, onPageChange };
};
