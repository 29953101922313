import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse, Pagination } from 'types';
import type { DeviceFilterQuery, IDevice } from './types';

export const deviceApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Device'] }).injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query<CoreResponse<IDevice[]>, Pagination<IDevice> & DeviceFilterQuery>({
      query: (params) => ({ url: '/hardware/devices', params }),
      providesTags: ['Device'],
    }),
    findDevice: builder.query<CoreResponse<IDevice>, string>({
      query: (id) => ({ url: `/hardware/devices/${id}` }),
      providesTags: ['Device'],
    }),
    createDevice: builder.mutation<CoreResponse<IDevice>, Partial<IDevice>>({
      query: (device) => ({
        url: '/hardware/devices',
        method: 'POST',
        body: { device },
      }),
      invalidatesTags: ['Device'],
    }),
    updateDevice: builder.mutation<void, Pick<IDevice, 'id'> & Partial<IDevice>>({
      query: ({ id, ...device }) => ({
        url: `/hardware/devices/${id}`,
        method: 'PUT',
        body: { device },
      }),
      invalidatesTags: ['Device'],
    }),
    deleteDevice: builder.mutation<IIdResponse, string>({
      query: (id) => ({ url: `/hardware/devices/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Device'],
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useLazyGetDevicesQuery,
  useFindDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
} = deviceApi;
