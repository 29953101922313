import { Route, Routes } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { DeviceList } from './routes/DeviceList';
import { DeviceCreate } from './routes/DeviceCreate';
import { DeviceShow } from './routes/DeviceShow';
import { DeviceEdit } from './routes/DeviceEdit';
import { TagCreate } from '../Tags/routes/TagCreate';

export const Devices = () => (
  <Routes>
    <Route element={<DeviceList />}>
      <Route index element={null} />
      <Route path="tags/:tagId" element={null} />
      <Route element={<ModalLayout />}>
        <Route path="new" element={<DeviceCreate />} />
        <Route path="tags/new" element={<TagCreate />} />
      </Route>
    </Route>
    <Route path=":id" element={<DeviceShow />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="edit" element={<DeviceEdit />} />
      </Route>
    </Route>
  </Routes>
);
