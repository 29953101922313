import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useAppSelector } from 'hooks/useAppStore';
import { useUpdateTagMutation } from 'services/api/tms/tags/api';

import { selectTagById } from '../../slice';
import { TagForm } from '../../components/TagForm';

export const TagEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const tag = useAppSelector((state) => selectTagById(state, Number(id)));
  const [update] = useUpdateTagMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareTags());
  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareTags()));
    },
    [update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування категорії</PageHeader>

      {tag ? (
        <TagForm defaultValues={tag} onCancel={handleDismiss} onSubmit={handleSubmit} />
      ) : (
        <Preloader />
      )}
    </Fragment>
  );
};
