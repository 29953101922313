import cs from 'classnames';

import styles from './Skeleton.module.scss';

interface ISkeletonProps {
  width?: number | string;
  height?: number | string;
  className?: string;
}

export const Skeleton = ({ className, width, height }: ISkeletonProps) => (
  <div className={cs(styles.line, className)} style={{ width, height }}></div>
);
