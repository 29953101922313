import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { latLngBounds } from 'leaflet';
import { Circle } from 'react-leaflet';
import {
  Button,
  Card,
  Col,
  Hint,
  Icon,
  InlineSpace,
  PageHeader,
  Preloader,
  Row,
  Tabs,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import {
  BackLink,
  DateView,
  Description,
  InlineRouteLink,
  MapBox,
  Picture,
  Point,
} from 'components';
import { useFindPosTerminalQuery } from 'services/api/tms/terminals/api';

import { TerminalStatus } from 'features/pos/components/TerminalStatus';
import styles from './TerminalShow.module.scss';

export const TerminalShow = () => {
  const { id } = useParams();
  const { terminal, isLoading } = useFindPosTerminalQuery(String(id), {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, terminal: response?.data }),
  });

  if (!terminal || isLoading) return <Preloader />;

  const bounds = latLngBounds([[terminal.merchant.latitude, terminal.merchant.longitude]]);
  if (terminal.device) bounds.extend([terminal.device.latitude, terminal.device.longitude]);

  return (
    <Fragment>
      <BackLink>Повернутись</BackLink>

      <Card className={styles.wrapper}>
        <PageHeader>
          TID: {terminal.tid} / MID: {terminal.merchant.mid}
          <InlineSpace />
          <TerminalStatus state={terminal.state} />
        </PageHeader>

        <Tabs
          className={styles.tabs}
          tabs={[
            {
              id: 'info',
              title: 'Основна інформація',
              content: (
                <Row gutter={10}>
                  <Col span={12} className={styles.column}>
                    <Row className={styles.inlineRow}>
                      <Col>
                        <Picture
                          src={terminal.device?.model?.photo}
                          icon="smartKasa"
                          size="XXL"
                          fill="grey"
                          className={styles.model}
                        />
                      </Col>
                      <Col>
                        <Description label="Банк:">
                          <InlineRouteLink to={ROUTES.bankShow(terminal.bank.id)}>
                            {terminal.bank.name}
                          </InlineRouteLink>
                        </Description>
                        <Description label="Назва торговця:">
                          <InlineRouteLink to={ROUTES.customerShow(terminal.customer.id)}>
                            {terminal.customer.registrationNumber}
                          </InlineRouteLink>
                          <InlineSpace size={6} />
                          {terminal.merchant.name}
                        </Description>
                        <Description label="Назва торгової точки:">
                          {terminal.merchant.shopName}
                          <Hint margin={0}>{terminal.merchant.shopAddress}</Hint>
                        </Description>
                      </Col>
                    </Row>

                    <Row className={styles.inlineRow}>
                      <Button color="sub-primary" className={styles.action}>
                        <Icon size="x-large" name="sync" className={styles.actionIcon} />
                        Встановити
                      </Button>

                      <Button color="light" className={styles.action}>
                        <Icon size="x-large" name="look" className={styles.actionIcon} />
                        Заблокувати
                      </Button>

                      <Button color="sub-accent" className={styles.action}>
                        <Icon size="x-large" name="trash" className={styles.actionIcon} />
                        Стерти
                      </Button>
                    </Row>
                  </Col>

                  <Col span={12}>
                    {terminal.device && (
                      <Row gutter={10}>
                        <Col span={12}>
                          <Description label="Серійний номер:">
                            <InlineRouteLink to={ROUTES.hardwareDeviceShow(terminal.device.id)}>
                              {terminal.device.serialNumber}
                            </InlineRouteLink>
                          </Description>
                        </Col>
                        <Col span={12}>
                          <Description label="Остання активність:">
                            <DateView value={terminal.device.lastOnlineAt} />
                          </Description>
                        </Col>
                      </Row>
                    )}

                    <MapBox bounds={bounds} className={styles.map}>
                      <Circle
                        center={[terminal.merchant.latitude, terminal.merchant.longitude]}
                        pathOptions={{ fillColor: 'orange', color: 'green' }}
                        radius={100}
                      />

                      <Point
                        position={[terminal.merchant.latitude, terminal.merchant.longitude]}
                        color="green"
                      />

                      {terminal.device && (
                        <Point position={[terminal.device.latitude, terminal.device.longitude]} />
                      )}
                    </MapBox>
                  </Col>
                </Row>
              ),
            },
            {
              id: 'settings',
              title: 'Налаштування',
              content: null,
            },
          ]}
        />
      </Card>
    </Fragment>
  );
};
