import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import SimpleBar from 'simplebar-react';
import { IMenuProps, Icon, Menu } from '@smart-kasa/ui';

import { MenuLink } from './MenuLink';
import { MenuGroup } from './MenuGroup';
import styles from './Navigation.module.scss';

export type NavigationProps = Omit<IMenuProps, 'children'> & {
  children?: never;
};

export const Navigation = (props: NavigationProps) => {
  const { formatMessage } = useIntl();

  return (
    <Menu {...props}>
      <SimpleBar className={styles.scrollbar}>
        <MenuLink
          title={formatMessage({ id: 'navigation.dashboard' })}
          to={ROUTES.dashboard()}
          icon={<Icon name="grid" />}
          badge="DEV"
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.customers' })}
          to={ROUTES.customers()}
          icon={<Icon name="business" />}
          badge="DEV"
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.terminals' })}
          to={ROUTES.terminals()}
          icon={<Icon name="files" />}
          badge="DEV"
        />
        <MenuGroup
          title={formatMessage({ id: 'navigation.pos' })}
          namespace={ROUTES.hardware()}
          icon={<Icon name="smartKasa" />}
        >
          <MenuLink
            title={formatMessage({ id: 'navigation.devices' })}
            to={ROUTES.hardwareDevices()}
            nested
            badge="DEV"
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.tags' })}
            to={ROUTES.hardwareTags()}
            nested
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.models' })}
            to={ROUTES.hardwareModels()}
            nested
          />
          <MenuLink
            title={formatMessage({ id: 'navigation.vendors' })}
            to={ROUTES.hardwareVendors()}
            nested
          />
        </MenuGroup>
        <MenuLink
          title={formatMessage({ id: 'navigation.map' })}
          to={ROUTES.map()}
          icon={<Icon name="location" />}
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.banks' })}
          to={ROUTES.banks()}
          icon={<Icon name="bank" />}
          badge="DEV"
        />
        <MenuGroup
          title={formatMessage({ id: 'navigation.instructions' })}
          namespace=""
          icon={<Icon name="reports" />}
          disabled
        >
          <MenuLink title={formatMessage({ id: 'navigation.events' })} to={ROUTES.events()} />
          <MenuLink title={formatMessage({ id: 'navigation.tasks' })} to={ROUTES.tasks()} />
          <MenuLink
            title={formatMessage({ id: 'navigation.transitions' })}
            to={ROUTES.transitions()}
          />
        </MenuGroup>
        <MenuGroup
          title={formatMessage({ id: 'navigation.hub' })}
          namespace={ROUTES.hub()}
          icon={<Icon name="security" />}
          disabled
        >
          <MenuLink title={formatMessage({ id: 'navigation.alerts' })} to={ROUTES.hubAlerts()} />
        </MenuGroup>
        <MenuLink
          title={formatMessage({ id: 'navigation.applications' })}
          to={ROUTES.appStore()}
          icon={<Icon name="shopApps" />}
          disabled
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.users' })}
          to={ROUTES.users()}
          icon={<Icon name="members" />}
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.profile' })}
          to={ROUTES.profile()}
          icon={<Icon name="user" />}
        />
        <MenuLink
          title={formatMessage({ id: 'navigation.settings' })}
          to={ROUTES.settings()}
          icon={<Icon name="settings" />}
          disabled
        />
      </SimpleBar>
    </Menu>
  );
};
