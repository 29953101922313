import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useAppSelector } from 'hooks/useAppStore';
import { useUpdateVendorMutation } from 'services/api/tms/vendors/api';

import { selectVendorById } from '../../slice';
import { VendorForm } from '../../components/VendorForm';

export const VendorEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const vendor = useAppSelector((state) => selectVendorById(state, Number(id)));
  const [update] = useUpdateVendorMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareVendors());
  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareVendors()));
    },
    [update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування постачальника</PageHeader>

      {vendor ? (
        <VendorForm defaultValues={vendor} onCancel={handleDismiss} onSubmit={handleSubmit} />
      ) : (
        <Preloader />
      )}
    </Fragment>
  );
};
