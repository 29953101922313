import { RadioSwitch, IRadioSwitchProps } from '@smart-kasa/ui';

import Field from '../Field/Field';

export type RadioSwitchFieldProps = IRadioSwitchProps<any> & {
  name: string;
};

export const RadioSwitchField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <RadioSwitch {...input} {...rest} {...props} onChange={(value) => input.onChange(value)} />
    )}
  </Field>
);
