import { Fragment, useCallback } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import {
  Badge,
  Button,
  Col,
  DropDown,
  Hint,
  Icon,
  InlineSpace,
  PageHeader,
  Preloader,
  Row,
  Tabs,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { BackRouteLink, DateView, Description, Panel, Picture } from 'components';
import { useDeleteDeviceMutation, useFindDeviceQuery } from 'services/api/tms/devices/api';

import { DeviceStatus } from '../../components/DeviceStatus';
import { DeviceOnlineStatus } from '../../components/DeviceOnlineStatus';
import { DeviceInfo } from '../../components/DeviceInfo';
import { DeviceLocation } from '../../components/DeviceLocation';
import { DeviceEvents } from '../../components/DeviceEvents';
import { DeviceTerminals } from '../../components/DeviceTerminals';

export const DeviceShow = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { id } = useParams();
  const { device, isLoading } = useFindDeviceQuery(String(id), {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, device: response?.data }),
  });
  const [destroy] = useDeleteDeviceMutation();

  const handleDelete = useCallback(
    async (id: string) => {
      const isConfirmed = await confirm(
        'Якщо Ви продовжите видалення обладнання, то це може спричинити пошкодження чи часткову втрату інформації, а також може вплинути на роботу із фінансовими операціями. Ви дійсно бажаєте видалити обладнання?'
      );

      if (isConfirmed)
        destroy(id)
          .unwrap()
          .then(() => navigate(ROUTES.hardwareDevices()));
    },
    [confirm, destroy, navigate]
  );

  if (!device || isLoading) return <Preloader />;

  return (
    <Fragment>
      <BackRouteLink to={ROUTES.hardwareDevices()}>Повернутись</BackRouteLink>
      <PageHeader
        actions={
          <Fragment>
            <Button variant="outlined" color="dark" rightIcon={<Icon name="sync" />}>
              Синхронізувати
            </Button>
            <InlineSpace />
            <DropDown>
              <DropDown.Toggle>
                <Button color="dark" rightIcon={<Icon name="arrowDownThin" size="small" />}>
                  Додатково
                </Button>
              </DropDown.Toggle>
              <DropDown.Menu>
                <DropDown.Item
                  icon="edit"
                  onClick={() => navigate(ROUTES.hardwareDeviceEdit(device.id))}
                >
                  Редагувати
                </DropDown.Item>
                <DropDown.Item icon="trash" onClick={() => handleDelete(device.id)}>
                  Видалити
                </DropDown.Item>
              </DropDown.Menu>
            </DropDown>
          </Fragment>
        }
      >
        {device.serialNumber}
      </PageHeader>

      <Panel>
        <Row gutter={10}>
          <Col span={8}>
            <Row gutter={10}>
              <Col>
                <Picture src={device.model?.photo} icon="smartKasa" size="XL" fill="grey" />
              </Col>
              <Col>
                <Description label="Модель:">{device.model?.name}</Description>
                <Description label="Категорії:">
                  {device.tags.length > 0 ? device.tags.join(', ') : '-'}
                </Description>
                <Hint>{device.note}</Hint>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Description label="Статус:">
              <DeviceStatus state={device.state} />
            </Description>
            <Description label="Онлайн статус:">
              <DeviceOnlineStatus state={device.networkState} />
            </Description>
            <Description label="Останній раз в мережі:">
              <DateView value={device.lastOnlineAt} />
            </Description>
          </Col>
          <Col span={8}>
            <Description label="Дата активації:">
              <DateView value={device.activatedAt} />
            </Description>
            <Description label="Дата створення:">
              <DateView value={device.createdAt} />
            </Description>
            <Description label="Дата останніх змін:">
              <DateView value={device.updatedAt} />
            </Description>
          </Col>
        </Row>
      </Panel>

      <InlineSpace direction="vertical" size={20} />

      <Tabs
        tabs={[
          {
            id: 'info',
            title: (
              <Fragment>
                Загальні відомості <Badge>В розробці</Badge>
              </Fragment>
            ),
            content: <DeviceInfo device={device} />,
          },
          {
            id: 'location',
            title: 'Геодані',
            content: <DeviceLocation lat={device.latitude} lng={device.longitude} />,
          },
          {
            id: 'pos',
            title: 'Мерчанти',
            content: <DeviceTerminals device={device} />,
          },
          {
            id: 'events',
            title: 'Журнал подій',
            content: <DeviceEvents device={device} />,
          },
        ]}
      />

      <Outlet />
    </Fragment>
  );
};
