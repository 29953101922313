import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardTitle, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useFindBankQuery, useUpdateBankMutation } from 'services/api/tms/banks/api';

import { BankForm } from '../../components/BankForm';

export const BankEdit = () => {
  const navigate = useNavigate();
  const { bankId } = useParams();
  const { data: bank } = useFindBankQuery(String(bankId));
  const [update] = useUpdateBankMutation();

  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => {
          navigate(ROUTES.bankShow(bankId));
        });
    },
    [bankId, update, navigate]
  );

  if (!bank) return <Preloader />;

  return (
    <Fragment>
      <CardTitle>Редагування банку</CardTitle>
      <BankForm defaultValues={bank?.data} onSubmit={handleSubmit} />
    </Fragment>
  );
};
