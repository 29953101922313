import { useContext, Fragment } from 'react';
import { PageHeader } from '@smart-kasa/ui';

import { SessionContext } from 'contexts/SessionContext';
import type { ILogin } from 'services/api/tms/auth/types';

import { LoginForm } from '../../components/LoginForm';
import { LoginFormErrorSchema } from '../../components/LoginForm/LoginFormSchema';

export const Login = () => {
  const { login } = useContext(SessionContext);

  const handleSubmit = async (values: ILogin) => {
    return await login(values)
      .unwrap()
      .catch((err) => LoginFormErrorSchema.get(err));
  };

  return (
    <Fragment>
      <PageHeader>Вхід</PageHeader>
      <LoginForm defaultValues={{}} onSubmit={handleSubmit} />
    </Fragment>
  );
};
