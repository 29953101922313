import { FC, ReactNode } from 'react';

import styles from './BluredView.module.scss';

export type BluredViewProps = {
  children?: ReactNode;
};

export const BluredView: FC<BluredViewProps> = ({ children }) => (
  <div className={styles.blured}>{children}</div>
);
