import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonText, CardTitle, Col, InlineSpace, PageHeader, Row } from '@smart-kasa/ui';

import { Description, Panel } from 'components';
import { BankTerminalsChart } from './components/BankTerminalsChart';

export const Dashboard = () => (
  <Fragment>
    <PageHeader>
      <FormattedMessage id="dashboard.title" defaultMessage="Панель керування та статистика" />
    </PageHeader>
    <Row gutter={10}>
      <Col span={8}>
        <Panel>
          <CardTitle>Обладнання</CardTitle>
          <Description label="Загальна кількість пристроїв:">12 368</Description>
          <Description label="Кількість активованих пристроїв:">7 355</Description>
          <Description label="Всього онлайн:">1001</Description>
        </Panel>
      </Col>
      <Col span={8}>
        <Panel>
          <CardTitle>Еквайринг та POS-термінали</CardTitle>
          <Description label="Кількість клієнтів:">893</Description>
          <Description label="Кількість встановлених POS-терміналів:">1001</Description>
          <Description label="Заявки що очікують встановлення:">11</Description>
        </Panel>
      </Col>
      <Col span={8}>
        <Panel>
          <CardTitle>Моніторинг</CardTitle>
          <Description label="Обладнання за межами точки торгівлі:">209</Description>
          <Description label="Статистика подій за добу:">103/83/1</Description>
        </Panel>
      </Col>
    </Row>

    <InlineSpace direction="vertical" size={35} />

    <Row gutter={20}>
      <Col span={8}>
        <CardTitle>Розподіл POS-терміналів між банками-еквайерами</CardTitle>

        <BankTerminalsChart />
      </Col>
      <Col span={16}>
        <CardTitle actions={<ButtonText>Переглянути всі операції</ButtonText>}>
          Останні заявки
        </CardTitle>
      </Col>
    </Row>

    <InlineSpace direction="vertical" size={35} />

    <CardTitle actions={<ButtonText>Переглянути всі операції</ButtonText>}>Історія подій</CardTitle>
  </Fragment>
);
