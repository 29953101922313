import { tmsApi } from '../api';
import type { CoreResponse } from 'types';
import type { AuthResetPassword, AuthResetPasswordCodeResponse, ILogin, ISession } from './types';

export const authApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<CoreResponse<ISession>, ILogin>({
      query: (session) => ({
        url: '/auth/sessions',
        method: 'POST',
        body: { session },
      }),
    }),
    requestPasswordChange: builder.mutation<CoreResponse<AuthResetPasswordCodeResponse>, string>({
      query: (email) => ({
        url: '/auth/passwords',
        method: 'POST',
        body: { user: { email } },
      }),
    }),
    resetPassword: builder.mutation<CoreResponse<AuthResetPasswordCodeResponse>, AuthResetPassword>(
      {
        query: ({ token, ...user }) => ({
          url: `/auth/passwords/${token}`,
          method: 'PUT',
          body: { user },
        }),
      }
    ),
  }),
});

export const { useSignInMutation, useRequestPasswordChangeMutation, useResetPasswordMutation } =
  authApi;
