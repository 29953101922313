import Ukrainian from './uk.json';

interface LocaleConfig {
  [key: string]: any;
}

const I18n: LocaleConfig = {
  uk: Ukrainian,
};

export default I18n;
