import { useAppSelector } from 'hooks/useAppStore';

import { useGetBanksQuery } from 'services/api/tms/banks/api';
import type { IBank } from 'services/api/tms/banks/types';

import { selectAllBanks } from '../slice';

type UseBanksResult = {
  banks: IBank[];
  isLoading: boolean;
  refresh: () => void;
};

export const useBanks = (): UseBanksResult => {
  const { refetch, isFetching } = useGetBanksQuery();
  const banks = useAppSelector((state) => selectAllBanks(state));

  return { banks, refresh: refetch, isLoading: isFetching };
};
