import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonIcon, CardTitle, IDataTableColumn, Icon, Strong } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { AttributeTable, DateView } from 'components';
import { useGetEventsQuery } from 'services/api/tms/events/api';
import type { IEvent } from 'services/api/tms/events/types';
import type { IDevice } from 'services/api/tms/devices/types';

import { EventStatus } from 'features/events/components/EventStatus';

export type DeviceEventsProps = {
  device: IDevice;
};

export const DeviceEvents = ({ device }: DeviceEventsProps) => {
  const navigate = useNavigate();
  const { data: events, isLoading } = useGetEventsQuery({ deviceId: device.id });

  const columns: IDataTableColumn<IEvent>[] = [
    {
      key: 'command',
      title: 'Інструкція',
      render: (command, { namespace }) => (
        <Strong>
          {namespace}.{command}
        </Strong>
      ),
    },
    {
      key: 'user',
      title: 'Користувач',
      render: (user) => user?.name,
    },
    {
      key: 'createdAt',
      title: 'Дата стоврення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'completedAt',
      title: 'Дата виконання',
      render: (completedAt) => (completedAt ? <DateView value={completedAt} /> : '-'),
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <EventStatus state={state} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <CardTitle>Перелік останніх подій</CardTitle>

      <AttributeTable
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        data={events?.data}
        isLoading={isLoading}
        onRowClick={(event) => navigate(ROUTES.eventShow(event.id))}
      />
    </Fragment>
  );
};
