import cs from 'classnames';
import { DataTable, IDataTableProps } from '@smart-kasa/ui';

import styles from './AttributeTable.module.scss';

export interface IAttributeTableProps<Data, IdKey extends keyof Data>
  extends IDataTableProps<Data, IdKey> {}

export const AttributeTable = ({ className, ...rest }: IAttributeTableProps<any, any>) => (
  <DataTable className={cs(styles.table, className)} {...rest} />
);
