import { tmsApi } from '../api';
import type { CoreResponse } from 'types';
import type { IProfile, IProfileChangePassword, IProfileUpdate } from './types';

export const profileApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Profile'] }).injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<CoreResponse<IProfile>, void>({
      query: () => ({ url: '/iam/profile' }),
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<CoreResponse<IProfile>, IProfileUpdate>({
      query: (profile) => ({
        url: '/iam/profile',
        method: 'PUT',
        body: { profile },
      }),
      invalidatesTags: ['Profile'],
    }),
    changeProfilePassword: builder.mutation<CoreResponse<IProfile>, IProfileChangePassword>({
      query: (profile) => ({
        url: '/iam/password',
        method: 'PUT',
        body: { profile },
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation, useChangeProfilePasswordMutation } =
  profileApi;
