import { Tag } from '@smart-kasa/ui';

import { UserState } from 'services/api/tms/users/enums';

const STATE_NAME: Record<UserState, { name: string; color: any }> = {
  [UserState.ACTIVATED]: {
    name: 'Активний',
    color: 'blue',
  },
  [UserState.DISABLED]: {
    name: 'Деактивований',
    color: 'orange',
  },
};

export type UserStatusProps = {
  state: UserState;
  children?: never;
};

export const UserStatus = ({ state }: UserStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
