import { Fragment } from 'react';
import { Card, CardTitle, Pagination, Row } from '@smart-kasa/ui';

import { DateView } from 'components';
import { usePagination } from 'hooks/usePagination';
import type { ICustomer } from 'services/api/tms/customers/types';
import { useGetMerchantsQuery } from 'services/api/tms/merchants/api';

export type CustomerMerchantsProps = {
  customer: ICustomer;
};

export const CustomerMerchants = ({ customer }: CustomerMerchantsProps) => {
  const { page, perPage, onPageChange } = usePagination();
  const { data: merchants } = useGetMerchantsQuery({ customerId: customer.id, page, perPage });

  return (
    <Fragment>
      <CardTitle>Список підключених мерчантів ({merchants?.meta?.totalCount})</CardTitle>

      <Row>
        {merchants?.data.map((merchant) => (
          <Card>
            <p>{merchant.mid}</p>
            <p>{merchant.name}</p>
            <p>{merchant.shopName}</p>
            <p>{merchant.shopAddress}</p>
            <p>{merchant.regionName}</p>
            <p>
              <DateView value={merchant.createdAt} />
            </p>
            <p>{merchant.contactName}</p>
            <p>{merchant.contactPhoneNumber}</p>
          </Card>
        ))}
      </Row>

      <Pagination
        totalItems={merchants?.meta?.totalCount}
        currentPage={page}
        perPage={perPage}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
