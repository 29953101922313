import { createSelector } from '@reduxjs/toolkit';

import { tagsApi } from 'services/api/tms/tags/api';

export const selectAllTags = createSelector(
  tagsApi.endpoints.getTags.select(),
  (result) => result?.data?.data ?? []
);

export const selectTagById = createSelector(
  [selectAllTags, (_state, tagId) => tagId],
  (tags, tagId) => tags?.find((tag) => tag.id === tagId)
);
