import { ReactNode, memo } from 'react';
import { Drawer } from '@smart-kasa/ui';

import { DeviceFilterForm, IDeviceFilterForm } from './DeviceFilterForm';

export type DeviceFilterProps = IDeviceFilterForm & {
  title?: ReactNode;
  show: boolean;
  onClose: () => void;
};

export const DeviceFilter: React.FC<DeviceFilterProps> = memo(
  ({ title, show, onSubmit, onClose, ...rest }) => {
    const handleSubmit = (data) => {
      onClose();
      onSubmit(data);
    };

    return (
      <Drawer title={title} show={show} onClose={onClose} footer={null}>
        <DeviceFilterForm onSubmit={handleSubmit} {...rest} />
      </Drawer>
    );
  }
);
