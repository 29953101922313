import { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import cs from 'classnames';
import { Menu, IMenuItemProps } from '@smart-kasa/ui';

import styles from './Navigation.module.scss';

export interface IMenuLinkProps extends Omit<IMenuItemProps, 'children'> {
  to: string;
}

export const MenuLink: FC<IMenuLinkProps> = ({ to, ...rest }) => {
  const match = useMatch(to);

  return (
    <Menu.Item
      {...rest}
      active={Boolean(match)}
      component={Link}
      componentProps={{ to, className: cs(styles.navItem, { [styles.active]: Boolean(match) }) }}
    />
  );
};
