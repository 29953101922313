import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateDeviceModelMutation } from 'services/api/tms/models/api';

import { ModelForm } from '../../components/ModelForm';

export const ModelCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateDeviceModelMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareModels());
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareModels()));
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Створення моделі</PageHeader>
      <ModelForm defaultValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
