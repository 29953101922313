import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, EmptyState } from '@smart-kasa/ui';

import { ROUTES } from 'routes';

import styles from './NoMatch.module.scss';

export const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>404</div>

        <EmptyState
          header={<FormattedMessage id="not_found.title" />}
          description={<FormattedMessage id="not_found.description" />}
        />

        <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.root())}>
          <FormattedMessage id="not_found.link" />
        </Button>
      </div>
    </div>
  );
};
