import { useNavigate, useParams } from 'react-router-dom';
import { Button, CardTitle, InlineSpace, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { Panel } from 'components';
import { useGetTagsQuery } from 'services/api/tms/tags/api';
import type { IDeviceTag } from 'services/api/tms/tags/types';

import { DeviceTagItem } from '../DeviceTagItem';

export type DeviceTagsProps = {
  onClick?: (tag: IDeviceTag) => void;
};

export const DeviceTags = ({ onClick }: DeviceTagsProps) => {
  const navigate = useNavigate();
  const { tagId } = useParams();
  const { data: tags, isLoading } = useGetTagsQuery();

  if (isLoading) return <Preloader />;

  return (
    <Panel>
      <CardTitle
        actions={
          tagId && (
            <Button color="light" size="small" onClick={() => navigate(ROUTES.hardwareDevices())}>
              Показати всі
            </Button>
          )
        }
      >
        Категорії
      </CardTitle>

      {tags?.data.map((tag) => (
        <DeviceTagItem
          key={tag.id}
          tag={tag}
          selected={tag.id === Number(tagId)}
          onClick={() => onClick && onClick(tag)}
        />
      ))}

      <InlineSpace direction="vertical" size={20} />

      <Button
        variant="outlined"
        color="dark"
        width="full"
        size="small"
        onClick={() => navigate(ROUTES.hardwareDevicesTagCreate())}
      >
        Додати категорію
      </Button>
    </Panel>
  );
};
