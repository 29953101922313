import { ButtonIcon, IDataTableColumn, IDataTableProps, Icon } from '@smart-kasa/ui';

import { AttributeTable, DateView } from 'components';
import type { IEvent } from 'services/api/tms/events/types';

import { EventStatus } from '../EventStatus';

export type EventTableProps = Omit<IDataTableProps<IEvent, 'id'>, 'rowIdKey' | 'columns'>;

export const EventTable = (props: EventTableProps) => {
  const columns: IDataTableColumn<IEvent>[] = [
    {
      key: 'serialNumber',
      title: 'Обладнання',
    },
    {
      key: 'command',
      title: 'Інструкція',
      render: (command, { namespace }) => `${namespace}.${command}`,
    },
    {
      key: 'user',
      title: 'Користувач',
      render: (user) => user?.name,
    },
    {
      key: 'createdAt',
      title: 'Дата стоврення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'completedAt',
      title: 'Дата виконання',
      render: (completedAt) => (completedAt ? <DateView value={completedAt} /> : '-'),
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <EventStatus state={state} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return <AttributeTable rowIdKey="id" columns={columns} emptyText="Список порожній" {...props} />;
};
