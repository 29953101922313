import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button } from '@smart-kasa/ui';

import { Field } from 'components';
import { useBanks } from 'features/banks/hooks/useBanks';

export interface IMapFilterForm {
  defaultValues: any;
  onSubmit: (values) => void;
}

export const MapFilterForm: React.FC<IMapFilterForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { banks } = useBanks();

  return (
    <Form<any> initialValues={defaultValues} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Field.Select
            name="bankId"
            placeholder="Оберіть банк"
            label="Банк"
            isClearable
            value={Number(values.bankId)}
            options={banks}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
          />
          <Field.Input name="serialNumber" label="Серійний номер" />
          <Field.Input name="tid" label="TID" />
          <Field.Input name="mid" label="MID" />
          <Button type="submit" color="dark" width="full">
            {formatMessage({ id: 'buttons.filter' })}
          </Button>
        </form>
      )}
    </Form>
  );
};
