import { FC } from 'react';
import cs from 'classnames';
import { Card, ICardProps } from '@smart-kasa/ui';

import styles from './Panel.module.scss';

export interface IPanelProps extends ICardProps {}

export const Panel: FC<IPanelProps> = ({ className, ...rest }) => (
  <Card className={cs(styles.panel, className)} {...rest} />
);
