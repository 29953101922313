import { Route, Routes } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { VendorList } from './routes/VendorList';
import { VendorCreate } from './routes/VendorCreate';
import { VendorEdit } from './routes/VendorEdit';

export const Vendors = () => (
  <Routes>
    <Route element={<VendorList />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="new" element={<VendorCreate />} />
        <Route path=":id/edit" element={<VendorEdit />} />
      </Route>
    </Route>
  </Routes>
);
