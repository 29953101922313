import { Routes, Route } from 'react-router-dom';

import { TaskList } from './routes/TaskList';
import { TaskShow } from './routes/TaskShow';

export const Tasks = () => (
  <Routes>
    <Route index element={<TaskList />} />
    <Route path="/:id" element={<TaskShow />} />
  </Routes>
);
