import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  Hint,
  IDataTableColumn,
  Icon,
  PageHeader,
  Pagination,
  Strong,
  Tag,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { AttributeTable, DateView } from 'components';
import { enumValueToKey } from 'utils/fromEnum';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useGetCustomersQuery } from 'services/api/tms/customers/api';
import type { ICustomer } from 'services/api/tms/customers/types';
import { CustomerState } from 'services/api/tms/customers/enums';

export const CustomerList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: customers, isLoading } = useGetCustomersQuery(query);

  const columns: IDataTableColumn<ICustomer>[] = [
    {
      key: 'organizationName',
      title: 'Назва торговця',
      render: (name, { id }) => (
        <Fragment>
          <Strong>{name}</Strong>
          <Hint margin={0}>#{id}</Hint>
        </Fragment>
      ),
    },
    {
      key: 'registrationNumber',
      title: 'ІПН/ЄДРПОУ',
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <Tag color="violet">{enumValueToKey(CustomerState, state)}</Tag>,
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.customerCreate())}>
            Додати клієнта
          </Button>
        }
      >
        Список клієнтів
      </PageHeader>

      <AttributeTable
        data={customers?.data}
        isLoading={isLoading}
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.customerShow(item.id))}
      />

      <Pagination
        totalItems={customers?.meta?.totalCount}
        currentPage={customers?.meta?.currentPage}
        perPage={customers?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <Outlet />
    </Fragment>
  );
};
