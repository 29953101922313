import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse, Pagination } from 'types';
import type { ICustomer, CustomerFilterQuery } from './types';

export const customersApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Customer'] }).injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<
      CoreResponse<ICustomer[]>,
      Pagination<ICustomer> & CustomerFilterQuery
    >({
      query: (params) => ({ url: '/customers', params }),
      providesTags: ['Customer'],
    }),
    findCustomer: builder.query<CoreResponse<ICustomer>, string>({
      query: (id) => ({ url: `/customers/${id}` }),
      providesTags: ['Customer'],
    }),
    createCustomer: builder.mutation<CoreResponse<ICustomer>, Partial<ICustomer>>({
      query: (customer) => ({
        url: '/customers',
        method: 'POST',
        body: { customer },
      }),
      invalidatesTags: ['Customer'],
    }),
    updateCustomer: builder.mutation<
      CoreResponse<ICustomer>,
      Pick<ICustomer, 'id'> & Partial<ICustomer>
    >({
      query: ({ id, ...customer }) => ({
        url: `/customers/${id}`,
        method: 'PUT',
        body: { customer },
      }),
      invalidatesTags: ['Customer'],
    }),
    deleteCustomer: builder.mutation<IIdResponse, string>({
      query: (id) => ({ url: `/customers/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Customer'],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useFindCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customersApi;
