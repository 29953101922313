import { HTMLAttributes } from 'react';
import { latLngBounds } from 'leaflet';
import { Tooltip } from 'react-leaflet';
import cs from 'classnames';
import { Preloader, Strong } from '@smart-kasa/ui';

import { MapBox, Point, PointCluster } from 'components';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/useAppStore';
import { useGetMarkersQuery } from 'services/api/tms/markers/api';

import { selectMarkers } from './slice';
import { MapFilter } from './components/MapFilter';
import styles from './Map.module.scss';

const MapWrapper = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cs(styles.wrapper, className)} {...rest} />
);

export const Map = () => {
  const { query, push } = useQuery();
  const { isLoading } = useGetMarkersQuery(query);
  const markers = useAppSelector((state) => selectMarkers(state, query));
  const bounds = latLngBounds(markers.map((marker) => marker.position));

  if (isLoading) return <Preloader />;

  return (
    <MapWrapper>
      <MapFilter defaultValues={query} onSubmit={(values) => push(values)} />
      <MapBox className={styles.map} bounds={bounds}>
        <PointCluster>
          {markers.map((marker) => (
            <Point key={marker.id} position={marker.position}>
              <Tooltip sticky>
                <Strong>{marker.serialNumber}</Strong>
              </Tooltip>
            </Point>
          ))}
        </PointCluster>
      </MapBox>
    </MapWrapper>
  );
};
