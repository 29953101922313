import { createContext } from 'react';

import { useScript } from 'hooks/useScript';

interface IGooglePlacesContext {
  isLoading: boolean;
  isSuccessful: boolean;
}

export const GooglePlacesContext = createContext<IGooglePlacesContext>({
  isLoading: true,
  isSuccessful: false,
});

export const GooglePlacesContextProvider = ({ children }) => {
  const { isLoading, isSuccessful } = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`
  );

  return (
    <GooglePlacesContext.Provider value={{ isLoading, isSuccessful }}>
      {children}
    </GooglePlacesContext.Provider>
  );
};
