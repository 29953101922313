import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IDeviceVendor } from './types';

export const vendorsApi = tmsApi
  .enhanceEndpoints({ addTagTypes: ['DeviceVendor'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVendors: builder.query<CoreResponse<IDeviceVendor[]>, void>({
        query: () => ({ url: '/hardware/vendors' }),
        providesTags: ['DeviceVendor'],
      }),
      createVendor: builder.mutation<CoreResponse<IDeviceVendor>, Partial<IDeviceVendor>>({
        query: (vendor) => ({
          url: '/hardware/vendors',
          method: 'POST',
          body: { vendor },
        }),
        invalidatesTags: ['DeviceVendor'],
      }),
      updateVendor: builder.mutation<void, Pick<IDeviceVendor, 'id'> & Partial<IDeviceVendor>>({
        query: ({ id, ...vendor }) => ({
          url: `/hardware/vendors/${id}`,
          method: 'PUT',
          body: { vendor },
        }),
        invalidatesTags: ['DeviceVendor'],
      }),
      deleteVendor: builder.mutation<IIdResponse, number>({
        query: (id) => ({ url: `/hardware/vendors/${id}`, method: 'DELETE' }),
        invalidatesTags: ['DeviceVendor'],
      }),
    }),
  });

export const {
  useGetVendorsQuery,
  useCreateVendorMutation,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
} = vendorsApi;
