import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { Reducer } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { tmsApi } from 'services/api/tms/api';
import { assetsApi } from 'services/api/tms/assets/api';
import { authSlice } from 'features/auth/slice';

import { RESET_STATE_ACTION_TYPE } from './actions/resetState';
import { unauthenticatedMiddleware } from './middlewares/unauthenticatedMiddleware';

const reducers = {
  [authSlice.name]: authSlice.reducer,
  [tmsApi.reducerPath]: tmsApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [authSlice.name],
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: persistedReducer,
    preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([unauthenticatedMiddleware, tmsApi.middleware, assetsApi.middleware]),
  });

export const store = setupStore();
export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof combinedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
