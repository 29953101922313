import { Tag } from '@smart-kasa/ui';

import { enumValueToKey } from 'utils/fromEnum';
import { PosTerminalState } from 'services/api/tms/terminals/enums';

export type TerminalStatusProps = {
  state: PosTerminalState;
  children?: never;
};

export const TerminalStatus = ({ state }: TerminalStatusProps) => (
  <Tag>{enumValueToKey(PosTerminalState, state)}</Tag>
);
