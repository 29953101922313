import * as yup from 'yup';

import { schemaPhone } from 'utils/sharedSchemas';
import { schemaError } from 'utils/schemaError';

export const ProfileSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phoneNumber: schemaPhone,
});

export const ProfileErrorSchema = schemaError({
  default: 'Помилка збереження',
  email: {
    default: 'Помилка email',
    invalid: 'Невірний формат email',
  },
  phoneNumber: {
    default: 'Помилка телефону',
    invalid: 'Невірний телефон',
  },
});
