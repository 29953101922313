import { FC, ReactNode } from 'react';
import cs from 'classnames';

import styles from './BlankView.module.scss';

export type BlankViewProps = {
  className?: string;
  children?: ReactNode;
};

export const BlankView: FC<BlankViewProps> = ({ className, children }) => (
  <div className={cs(styles.content, className)}>
    <div className={styles.text}>{children}</div>
  </div>
);

BlankView.defaultProps = {
  children: 'Розділ пустий',
};
