import { ComponentType, ReactNode } from 'react';
import { useField } from 'react-final-form';
import { FormElement, Label } from '@smart-kasa/ui';

export type WithFormProps = {
  className?: string;
  description?: ReactNode;
  direction?: 'row' | 'column' | 'row-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between';
  alignItems?: 'center';
  label?: ReactNode;
  required?: boolean;
  name: string;
};

export const withFinalForm =
  <ComponentProps extends object>(Cmp: ComponentType<ComponentProps>) =>
  ({
    className,
    label,
    name,
    required,
    direction,
    justifyContent,
    alignItems,
    description,
    ...rest
  }: ComponentProps & WithFormProps) => {
    const { input, meta } = useField(name, { parse: (value) => value });
    const error = meta.touched && (meta.error || meta.submitError);

    return (
      <FormElement
        className={className}
        description={description}
        direction={direction}
        justifyContent={justifyContent}
        alignItems={alignItems}
        error={error}
        label={
          label && (
            <Label htmlFor={name} required={required}>
              {label}
            </Label>
          )
        }
      >
        <Cmp name={name} {...(input as any)} {...rest} />
      </FormElement>
    );
  };
