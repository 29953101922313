import cs from 'classnames';
import { Strong, Hint, Icon } from '@smart-kasa/ui';

import type { IDeviceTag } from 'services/api/tms/tags/types';

import styles from './DeviceTagItem.module.scss';

export type DeviceTagItemProps = {
  tag: IDeviceTag;
  children?: never;
  selected?: boolean;
  onClick?: () => void;
};

export const DeviceTagItem = ({ tag, selected = false, ...rest }: DeviceTagItemProps) => (
  <div className={cs(styles.tag, { [styles.selected]: selected })} {...rest}>
    <div className={styles.content}>
      <Strong>{tag.name}</Strong>
      <Hint margin={0}>{tag.description}</Hint>
    </div>
    {!selected && <Icon name="arrowRight" size="x-medium" />}
  </div>
);
