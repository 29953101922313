import { Routes, Route } from 'react-router-dom';

import { CustomerList } from './routes/CustomerList';
import { CustomerCreate } from './routes/CustomerCreate';
import { CustomerShow } from './routes/CustomerShow';
import { CustomerEdit } from './routes/CustomerEdit';

export const Customers = () => (
  <Routes>
    <Route index element={<CustomerList />} />
    <Route path="/new" element={<CustomerCreate />} />
    <Route path=":id" element={<CustomerShow />} />
    <Route path="/:id/edit" element={<CustomerEdit />} />
  </Routes>
);
