import React, { ReactNode } from 'react';

import type { IProfile } from 'services/api/tms/profile/types';
import { useGetProfileQuery } from 'services/api/tms/profile/api';

interface UserContextProps {
  profile?: IProfile;
  isLoading: boolean;
  refresh: () => void;
}

export const UserContext = React.createContext<UserContextProps>({} as any);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { data: user, isLoading, refetch } = useGetProfileQuery();

  return (
    <UserContext.Provider value={{ profile: user?.data, isLoading, refresh: refetch }}>
      {children}
    </UserContext.Provider>
  );
};
