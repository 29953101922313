import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IGateway } from './types';

export const gatewaysApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Gateway'] }).injectEndpoints({
  endpoints: (builder) => ({
    getGateways: builder.query<CoreResponse<IGateway[]>, number>({
      query: (bankId) => ({ url: `/banks/${bankId}/gateways` }),
      providesTags: ['Gateway'],
    }),
    findGateway: builder.query<CoreResponse<IGateway>, { bankId: number; id: number }>({
      query: ({ bankId, id }) => ({ url: `/banks/${bankId}/gateways/${id}` }),
      providesTags: ['Gateway'],
    }),
    createGateway: builder.mutation<CoreResponse<IGateway>, Partial<IGateway>>({
      query: ({ bankId, ...gateway }) => ({
        url: `/banks/${bankId}/gateways`,
        method: 'POST',
        body: { gateway },
      }),
      invalidatesTags: ['Gateway'],
    }),
    updateGateway: builder.mutation<
      CoreResponse<IGateway>,
      Pick<IGateway, 'id'> & Partial<IGateway>
    >({
      query: ({ bankId, id, ...gateway }) => ({
        url: `/banks/${bankId}/gateways/${id}`,
        method: 'PUT',
        body: { gateway },
      }),
      invalidatesTags: ['Gateway'],
    }),
    deleteGateway: builder.mutation<IIdResponse, { bankId: number; id: number }>({
      query: ({ bankId, id }) => ({
        url: `/banks/${bankId}/gateways/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Gateway'],
    }),
  }),
});

export const {
  useGetGatewaysQuery,
  useFindGatewayQuery,
  useCreateGatewayMutation,
  useUpdateGatewayMutation,
  useDeleteGatewayMutation,
} = gatewaysApi;
