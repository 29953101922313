import { Route, Routes } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { TagList } from './routes/TagList';
import { TagCreate } from './routes/TagCreate';
import { TagEdit } from './routes/TagEdit';

export const Tags = () => (
  <Routes>
    <Route element={<TagList />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="new" element={<TagCreate />} />
        <Route path=":id/edit" element={<TagEdit />} />
      </Route>
    </Route>
  </Routes>
);
