import { Fragment } from 'react';
import { useParams, Outlet, NavLink } from 'react-router-dom';
import {
  Preloader,
  AvatarItem,
  Layout,
  Row,
  Col,
  Strong,
  Tag,
  Title,
  InlineSpace,
  Icon,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { BackRouteLink, DateView, Panel, Picture } from 'components';
import { useFindBankQuery } from 'services/api/tms/banks/api';

import styles from './BankShow.module.scss';

export const BankShow = () => {
  const { bankId } = useParams();
  const { bank } = useFindBankQuery(String(bankId), {
    selectFromResult: ({ data: response }) => ({ bank: response?.data }),
  });

  if (!bank) return <Preloader />;

  return (
    <Fragment>
      <BackRouteLink to={ROUTES.banks()}>Повернутись</BackRouteLink>

      <AvatarItem
        avatar={<Picture src={bank?.logo} icon="bank" size="L" fill="grey" />}
        primaryText={<Title variant="h1">{bank.name}</Title>}
        secondaryText={
          <Fragment>
            <DateView value={bank.updatedAt} />
            <InlineSpace />
            {bank.website}
          </Fragment>
        }
      />

      <Layout className={styles.wrapper}>
        {bank.gateway && (
          <Layout.Header>
            <Row gutter={10} className={styles.toolbar}>
              <Col>
                <Strong className={styles.toolbarLabel}>IP адреса:</Strong>
                {bank.gateway.host}
              </Col>
              <Col>
                <Strong className={styles.toolbarLabel}>Порт:</Strong>
                {bank.gateway.port}
              </Col>
              <Col>
                <Strong className={styles.toolbarLabel}>Середовище:</Strong>
                <Tag color="violet">{bank.gateway.environment}</Tag>
              </Col>
              <Col>
                <Strong className={styles.toolbarLabel}>Специфікація:</Strong>
                {bank.gateway.specificationId}
              </Col>
              <Col>
                <Strong className={styles.toolbarLabel}>SSL:</Strong>
                <Icon name={bank.gateway.useSSL ? 'check' : 'close'} size="small" />
              </Col>
            </Row>
          </Layout.Header>
        )}

        <Layout className={styles.container}>
          <Layout.Sider>
            <Panel className={styles.sidebar}>
              <NavLink to={ROUTES.bankShow(bankId)} end>
                Загальні відомості
              </NavLink>
              <NavLink to={ROUTES.bankGateways(bankId)}>Платіжні шлюзи</NavLink>
              <NavLink to={ROUTES.bankTerminals(bankId)}>Торговці та пристрої</NavLink>
              <NavLink to={ROUTES.bankEdit(bankId)}>Налаштування</NavLink>
              <NavLink to={ROUTES.bankDelete(bankId)}>Видалення</NavLink>
            </Panel>
          </Layout.Sider>

          <Layout.Content className={styles.content}>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Fragment>
  );
};
