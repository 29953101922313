import { Fragment } from 'react';
import { AvatarItem, Hint, IDataTableColumn, PageHeader, Pagination } from '@smart-kasa/ui';

import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { AttributeTable, DateView, Picture } from 'components';
import { useGetTransitionsQuery } from 'services/api/tms/transitions/api';
import type { ITransition } from 'services/api/tms/transitions/types';

export const Transitions = () => {
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: transitions, isLoading } = useGetTransitionsQuery(query);

  const columns: IDataTableColumn<ITransition>[] = [
    {
      key: 'actor',
      title: 'Користувач',
      render: (actor, { note }) => (
        <AvatarItem
          avatar={<Picture src={actor?.photo?.url} icon="user" size="M" fill="grey" />}
          primaryText={actor?.name}
          secondaryText={<Hint margin={0}>{note}</Hint>}
        />
      ),
    },
    {
      key: 'event',
      title: 'Подія',
    },
    {
      key: 'ipAddress',
      title: 'IP адреса',
    },
    {
      key: 'createdAt',
      title: 'Дата',
      render: (createdAt) => <DateView value={createdAt} />,
    },
  ];

  return (
    <Fragment>
      <PageHeader>Активність користувачів</PageHeader>

      <AttributeTable
        data={transitions?.data}
        isLoading={isLoading}
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
      />

      <Pagination
        totalItems={transitions?.meta?.totalCount}
        currentPage={transitions?.meta?.currentPage}
        perPage={transitions?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
