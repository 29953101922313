import { Route, Routes } from 'react-router';

import { NoMatch } from 'components';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';
import { AppLayout } from 'layouts/AppLayout';
import { AuthLayout } from 'layouts/AuthLayout';
import { Auth } from 'features/auth';
import { Dashboard } from 'features/dashboard';
import { Store } from 'features/store';
import { Banks } from 'features/banks';
import { Customers } from 'features/customers';
import { Terminals } from 'features/pos';
import { Settings } from 'features/settings';
import { Users } from 'features/users';
import { Profile } from 'features/profile';
import { Transitions } from 'features/transitions';
import { Hardware } from 'features/hardware';
import { Events } from 'features/events';
import { Tasks } from 'features/tasks';
import { Map } from 'features/map';

const Router = () => (
  <Routes>
    <Route
      element={
        <AuthRoute>
          <AuthLayout />
        </AuthRoute>
      }
    >
      <Route path="auth/*" element={<Auth />} />
    </Route>

    <Route
      element={
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      }
    >
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="appstore/*" element={<Store />} />
      <Route path="customers/*" element={<Customers />} />
      <Route path="hardware/*" element={<Hardware />} />
      <Route path="banks/*" element={<Banks />} />
      <Route path="map" element={<Map />} />
      <Route path="terminals/*" element={<Terminals />} />
      <Route path="users/*" element={<Users />} />
      <Route path="profile/*" element={<Profile />} />
      <Route path="events/*" element={<Events />} />
      <Route path="tasks/*" element={<Tasks />} />
      <Route path="transitions" element={<Transitions />} />
      <Route path="settings" element={<Settings />} />
    </Route>
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export default Router;
