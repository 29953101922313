import { Fragment, useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, CardTitle, DropDown, IDataTableColumn, Tag } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { useDeleteGatewayMutation, useGetGatewaysQuery } from 'services/api/tms/gateways/api';
import type { IGateway } from 'services/api/tms/gateways/types';

import { AttributeTable } from 'components';

export const GatewayList = () => {
  const { bankId } = useParams();
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { data: gateways, isLoading } = useGetGatewaysQuery(Number(bankId));
  const [destroy] = useDeleteGatewayMutation();

  const handleDelete = useCallback(
    async (id: number) => {
      const isConfirmed = await confirm('Ви дійсно бажаєте видалити платіжний шлюз?');

      if (isConfirmed) destroy({ bankId: Number(bankId), id });
    },
    [bankId, confirm, destroy]
  );

  const columns: IDataTableColumn<IGateway>[] = [
    {
      key: 'environment',
      title: 'Середовище',
      render: (environment, { isPrimary }) => (
        <Tag color={isPrimary ? 'violet' : 'green'}>{environment}</Tag>
      ),
    },
    {
      key: 'host',
      title: 'IP адреса',
    },
    {
      key: 'port',
      title: 'Порт',
    },
    {
      key: 'useSSL',
      title: 'SSL',
    },
    {
      key: 'specificationId',
      title: 'Специфікація',
    },
    {
      key: 'id',
      render: (id) => (
        <DropDown type="dots">
          <DropDown.Menu>
            <DropDown.Item icon="edit" onClick={() => navigate(ROUTES.bankGatewayEdit(bankId, id))}>
              Редагувати
            </DropDown.Item>
            <DropDown.Item icon="trash" onClick={() => handleDelete(id)}>
              Видалити
            </DropDown.Item>
          </DropDown.Menu>
        </DropDown>
      ),
    },
  ];

  return (
    <Fragment>
      <CardTitle
        actions={
          <Button
            variant="outlined"
            width="narrow"
            color="dark"
            onClick={() => navigate(ROUTES.bankGatewayCreate(bankId))}
          >
            Додати
          </Button>
        }
      >
        Перелік платіжних шлюзів
      </CardTitle>

      <AttributeTable
        rowIdKey="id"
        data={gateways?.data}
        isLoading={isLoading}
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(gateway) => navigate(ROUTES.bankGatewayEdit(bankId, gateway.id))}
      />

      <Outlet />
    </Fragment>
  );
};
