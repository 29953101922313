import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { useFindGatewayQuery, useUpdateGatewayMutation } from 'services/api/tms/gateways/api';

import { GatewayForm } from '../../components/GatewayForm';

export const GatewayEdit = () => {
  const { bankId, id } = useParams();
  const navigate = useNavigate();
  const { data: gateway } = useFindGatewayQuery({ bankId: Number(bankId), id: Number(id) });
  const [update] = useUpdateGatewayMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      update({ ...values, bankId })
        .unwrap()
        .then(() => navigate(-1));
    },
    [bankId, update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування платіжного шлюзу</PageHeader>

      {gateway ? (
        <GatewayForm
          defaultValues={gateway?.data}
          onCancel={handleDismiss}
          onSubmit={handleSubmit}
        />
      ) : (
        <Preloader />
      )}
    </Fragment>
  );
};
