import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateBankMutation } from 'services/api/tms/banks/api';

import { BankForm } from '../../components/BankForm';
import { BackRouteLink } from 'components';

export const BankCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateBankMutation();

  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(({ data: bank }) => {
          navigate(ROUTES.bankShow(bank.id));
        });
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <BackRouteLink to={ROUTES.banks()}>Повернутись</BackRouteLink>
      <PageHeader>Додання нової фінансової організації</PageHeader>
      <BankForm defaultValues={{}} onSubmit={handleSubmit} />
    </Fragment>
  );
};
