import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse, Pagination } from 'types';
import type { IUser } from './types';

export const usersApi = tmsApi.enhanceEndpoints({ addTagTypes: ['User'] }).injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<CoreResponse<IUser[]>, Pagination<IUser>>({
      query: (params) => ({ url: '/iam/users', params }),
      providesTags: ['User'],
    }),
    findUser: builder.query<CoreResponse<IUser>, number>({
      query: (id) => ({ url: `/iam/users/${id}` }),
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<void, Pick<IUser, 'id'> & Partial<IUser>>({
      query: ({ id, ...user }) => ({
        url: `/iam/users/${id}`,
        method: 'PUT',
        body: { user },
      }),
      invalidatesTags: ['User'],
    }),
    activateUser: builder.mutation<IIdResponse, number>({
      query: (id) => ({ url: `/iam/users/${id}/activate`, method: 'POST' }),
      invalidatesTags: ['User'],
    }),
    blockUser: builder.mutation<IIdResponse, number>({
      query: (id) => ({ url: `/iam/users/${id}/block`, method: 'POST' }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<IIdResponse, number>({
      query: (id) => ({ url: `/iam/users/${id}`, method: 'DELETE' }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useFindUserQuery,
  useUpdateUserMutation,
  useActivateUserMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
} = usersApi;
