export enum BusinessEntityType {
  LIMITED_LIABILITY_COMPANY = 0,
  JOINT_STOCK_COMPANY = 1,
  SOLE_PROPRIETORSHIP = 2,
  LIMITED_PARTNERSHIP = 3,
  COOPERATIVE = 4,
  LIMITED_LIABILITY_JOINT_STOCK_COMPANY = 5,
  PUBLIC_JOINT_STOCK_COMPANY = 6,
  CONSUMER_COOPERATIVE = 7,
  FARM_ENTERPRISE = 8,
  ECONOMIC_ASSOCIATION = 9,
}

export enum CustomerState {
  ACTIVE = 0,
  INACTIVE = 1,
}
