import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, FormElement } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IDeviceTag } from 'services/api/tms/tags/types';

import { TagFormSchema } from './TagFormSchema';

export interface ITagForm {
  defaultValues: Partial<IDeviceTag>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const TagForm: FC<ITagForm> = ({ defaultValues, onCancel, onSubmit }) => (
  <Form<any>
    validate={yupResolver(TagFormSchema)}
    initialValues={defaultValues}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field.Input name="name" label="Назва" required />
        <Field.TextArea name="description" label="Опис" />

        <FormElement direction="row" justifyContent="flex-end">
          <Button variant="text" color="dark" onClick={onCancel}>
            Скасувати
          </Button>
          <Button width="narrow" color="dark" type="submit">
            Зберегти
          </Button>
        </FormElement>
      </form>
    )}
  </Form>
);
