import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, ButtonText, FormElement, InlineSpace } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { Field, Error } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { SignupFormSchema } from './SignupFormSchema';

interface ISignupForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const SignupForm: FC<ISignupForm> = ({ defaultValues, onSubmit }) => {
  const navigate = useNavigate();

  return (
    <Form<any>
      validate={yupResolver(SignupFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitErrors }) => (
        <form onSubmit={handleSubmit}>
          <Field.Input name="name" label="ПІБ" />
          <Field.Input name="phoneNumber" label="Номер телефону" />
          <Field.Password name="password" label="Пароль" type="password" />
          <Field.Password name="passwordConfirmation" label="Повторіть пароль" type="password" />

          {submitErrors && <Error>{submitErrors.base}</Error>}

          <FormElement alignItems="center">
            <Button type="submit" color="orange" width="full">
              <FormattedMessage id="auth.buttons.signup" />
            </Button>
            <InlineSpace direction="vertical" />
            <ButtonText onClick={() => navigate(ROUTES.authLogin())}>
              <FormattedMessage id="auth.navigation.login" />
            </ButtonText>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
