import { Select, SelectProps } from '@smart-kasa/ui';

import Field from '../Field/Field';

export type SelectFieldProps = Omit<SelectProps, 'value'> & {
  name: string;
  value?: any;
};

export const SelectField = ({ name, onChange, ...props }: SelectFieldProps) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <Select
        {...input}
        {...rest}
        {...props}
        onChange={(value, option) => {
          input.onChange(value);

          onChange && onChange(value, option);
        }}
      />
    )}
  </Field>
);
