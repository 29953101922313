import { Routes, Route } from 'react-router-dom';

import { NoMatch } from 'components';

import { Login } from './routes/Login';
import { Registration } from './routes/Registration';
import { PasswordRecovery } from './routes/PasswordRecovery';

export const Auth = () => (
  <Routes>
    <Route path="/sign-in" element={<Login />} />
    <Route path="/sign-up/:token" element={<Registration />} />
    <Route path="/password-recovery" element={<PasswordRecovery />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);
