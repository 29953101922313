import { FC, Fragment } from 'react';
import {
  AvatarItem,
  ButtonIcon,
  Hint,
  IDataTableColumn,
  IDataTableProps,
  Icon,
  Strong,
} from '@smart-kasa/ui';

import { AttributeTable, DateView, Picture } from 'components';
import type { IPosTerminal } from 'services/api/tms/terminals/types';

import { TerminalStatus } from '../TerminalStatus';

export interface ITerminalTable
  extends Omit<IDataTableProps<IPosTerminal, 'id'>, 'rowIdKey' | 'columns'> {}

export const TerminalTable: FC<ITerminalTable> = (props) => {
  const columns: IDataTableColumn<IPosTerminal>[] = [
    {
      key: 'merchant',
      title: 'TID/MID',
      render: ({ mid }, { tid, device }) => (
        <AvatarItem
          avatar={<Icon name="terminal" size="x-large" />}
          primaryText={
            <Strong>
              {tid} / {mid}
            </Strong>
          }
          secondaryText={device?.serialNumber}
        />
      ),
    },
    {
      key: 'bank',
      title: 'Банк-еквайер',
      render: ({ logo, name }) =>
        logo ? <Picture src={logo} icon="bank" size="L" fill="grey" /> : name,
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <TerminalStatus state={state} />,
    },
    {
      key: 'merchant',
      title: 'Назва торговця',
      render: ({ name }) => name,
    },
    {
      key: 'merchant',
      title: 'Назва торгової точки',
      render: ({ shopName, shopAddress }) => (
        <Fragment>
          {shopName}
          <Hint margin={0}>{shopAddress}</Hint>
        </Fragment>
      ),
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="medium" name="arrowRight" />
        </ButtonIcon>
      ),
    },
  ];

  return <AttributeTable rowIdKey="id" columns={columns} emptyText="Список порожній" {...props} />;
};
