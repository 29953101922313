import { ChangeEvent, Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Button, Col, Icon, InlineSpace, Input, PageHeader, Pagination, Row } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useDebounce } from 'hooks/useDebounce';
import useModal from 'hooks/useModal';
import { useGetDevicesQuery } from 'services/api/tms/devices/api';

import { DeviceTags } from '../../components/DeviceTags';
import { DeviceTable } from '../../components/DeviceTable';
import { DeviceFilter } from '../../components/DeviceFilter';
import styles from './DeviceList.module.scss';

export const DeviceList = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { tagId } = useParams();
  const { query, push } = useQuery();
  const { isShowing, showModal, hideModal } = useModal();
  const [searchTerm, setSearchTerm] = useState<string>(query.serialNumber);
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);
  const { onPageChange } = useQueryPagination();
  const { data: devices, isFetching } = useGetDevicesQuery({
    ...query,
    tagId,
    serialNumber: debouncedSearchTerm,
  });

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value);

  return (
    <Fragment>
      <PageHeader
        actions={
          <Row>
            <Input
              placeholder={formatMessage({ id: 'hardware.device.serial' })}
              value={searchTerm}
              onChange={handleSearch}
              className={styles.search}
            />
            <InlineSpace />
            <Button
              variant="outlined"
              color="dark"
              onClick={() => navigate(ROUTES.hardwareDeviceCreate())}
            >
              Зареєсрувати пристрій
            </Button>
            <InlineSpace />
            <Button
              color="dark"
              rightIcon={<Icon name="filter" size="medium" />}
              onClick={showModal}
            >
              Фільтр
            </Button>
          </Row>
        }
      >
        Список обладнання
      </PageHeader>

      <Row gutter={10}>
        <Col span={6}>
          <DeviceTags onClick={(tag) => navigate(ROUTES.hardwareDevicesByTag(tag.id))} />
        </Col>
        <Col span={18}>
          <DeviceTable
            data={devices?.data}
            isLoading={isFetching}
            onRowClick={(item) => navigate(ROUTES.hardwareDeviceShow(item.id))}
          />

          <Pagination
            totalItems={devices?.meta?.totalCount}
            currentPage={devices?.meta?.currentPage}
            perPage={devices?.meta?.limitValue}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>

      <DeviceFilter
        title="Фільтрація"
        show={isShowing}
        defaultValues={query}
        onSubmit={(values) => push(values)}
        onClose={hideModal}
      />

      <Outlet />
    </Fragment>
  );
};
