import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from 'services/queries/authBaseQuery';

import type { AssetFile } from './types';

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    upload: builder.mutation<AssetFile, File>({
      query: (file) => {
        const payload = new FormData();
        payload.append('file', file);

        return {
          url: '/assets/upload',
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const { useUploadMutation } = assetsApi;
