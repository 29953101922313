import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { SessionContext } from 'contexts/SessionContext';
import { UserContextProvider } from 'contexts/UserContext';

const ProtectedRoute = ({ children }) => {
  const { isLogged } = useContext(SessionContext);
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
  }

  return <UserContextProvider>{children}</UserContextProvider>;
};

export default ProtectedRoute;
