import * as yup from 'yup';
import { schemaError } from 'utils/schemaError';

export const LoginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Недопустимий формат. Перевірте правильність вводу та спробуйте ще раз')
    .required("Обов'язкове для заповнення поле"),
  password: yup.string().required("Обов'язкове для заповнення поле"),
});

export const LoginFormErrorSchema = schemaError({
  default: 'Невірний номер телефону чи пароль',
});
