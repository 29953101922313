import { Routes, Route } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { GatewayList } from './routes/GatewayList';
import { GatewayCreate } from './routes/GatewayCreate';
import { GatewayEdit } from './routes/GatewayEdit';

export const Gateways = () => (
  <Routes>
    <Route element={<GatewayList />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="new" element={<GatewayCreate />} />
        <Route path=":id" element={<GatewayEdit />} />
      </Route>
    </Route>
  </Routes>
);
