import { createSelector } from '@reduxjs/toolkit';

import { vendorsApi } from 'services/api/tms/vendors/api';

export const selectAllVendors = createSelector(
  vendorsApi.endpoints.getVendors.select(),
  (result) => result?.data?.data ?? []
);

export const selectVendorById = createSelector(
  [selectAllVendors, (_state, vendorId) => vendorId],
  (vendors, vendorId) => vendors?.find((vendor) => vendor.id === vendorId)
);
