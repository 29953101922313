import { useAppSelector } from 'hooks/useAppStore';

import { useGetVendorsQuery } from 'services/api/tms/vendors/api';
import type { IDeviceVendor } from 'services/api/tms/vendors/types';

import { selectAllVendors } from '../slice';

type UseDeviceVendorsResult = {
  vendors: IDeviceVendor[];
  isLoading: boolean;
  refresh: () => void;
};

export const useDeviceVendors = (): UseDeviceVendorsResult => {
  const { refetch, isFetching } = useGetVendorsQuery();
  const vendors = useAppSelector((state) => selectAllVendors(state));

  return { vendors, refresh: refetch, isLoading: isFetching };
};
