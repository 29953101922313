import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { HttpStatusCode } from 'services/http/enums';
import { resetStateAction } from '../actions/resetState';

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === HttpStatusCode.Unauthorized) {
      dispatch(resetStateAction());
    }

    return next(action);
  };
