import { HTMLAttributes } from 'react';
import cs from 'classnames';

import LightLogo from 'assets/logo_light.svg';
import DarkLogo from 'assets/logo.svg';

import styles from './Logo.module.scss';

export type LogoProps = HTMLAttributes<HTMLImageElement> & {
  theme?: 'dark' | 'light';
};

export const Logo = ({ className, theme = 'dark', ...rest }: LogoProps) => (
  <div className={cs(styles.wrapper, className)}>
    <img
      src={theme === 'light' ? LightLogo : DarkLogo}
      alt="Logo"
      {...rest}
      className={styles.logo}
    />
  </div>
);
