import { tmsApi } from '../api';
import type { CoreResponse, Pagination } from 'types';
import type { ITransition, TransitionFilterQuery } from './types';

export const transitionsApi = tmsApi
  .enhanceEndpoints({ addTagTypes: ['Transition'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTransitions: builder.query<
        CoreResponse<ITransition[]>,
        Pagination<ITransition> & TransitionFilterQuery
      >({
        query: (params) => ({ url: '/transitions', params }),
        providesTags: ['Transition'],
      }),
    }),
  });

export const { useGetTransitionsQuery } = transitionsApi;
