import { Tag } from '@smart-kasa/ui';

import { EventState } from 'services/api/tms/events/enums';

const STATE_NAME: Record<EventState, { name: string; color: any }> = {
  [EventState.PENDING]: {
    name: 'Очікує виконання',
    color: 'grey',
  },
  [EventState.QUEUED]: {
    name: 'В черзі на обробку',
    color: 'yellow',
  },
  [EventState.IN_PROGRESS]: {
    name: 'Виконується',
    color: 'violet',
  },
  [EventState.COMPLETED]: {
    name: 'Виконано',
    color: 'green',
  },
  [EventState.CANCELLED]: {
    name: 'Скасовано',
    color: 'orange',
  },
};

export type EventStatusProps = {
  state: EventState;
  children?: never;
};

export const EventStatus = ({ state }: EventStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
