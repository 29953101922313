import forEach from 'lodash/forEach';

type ErrorObjectValue = { error: string };
/* Error example:
   {
     "errors": {
      "base": [
        {
          "error": "phone_number_or_password_invalid"
        }
      ]
    }
  }
*/
export const parseErrors = (data) => {
  const obj = {};
  const errors = data.errors as { [key: string]: ErrorObjectValue[] };

  /* Transform into
    errorKey - first error from ErrorObjectValue
    {
      [fieldName]: errorKey
    }

    Example:
    {
      base: "phone_number_or_password_invalid"
    }
  */
  forEach(errors, (value, key) => {
    const error = value[0].error;

    obj[key] = error;
  });

  return obj;
};
