import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IDeviceTag } from './types';

export const tagsApi = tmsApi.enhanceEndpoints({ addTagTypes: ['DeviceTag'] }).injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<CoreResponse<IDeviceTag[]>, void>({
      query: () => ({ url: '/hardware/tags' }),
      providesTags: ['DeviceTag'],
    }),
    createTag: builder.mutation<CoreResponse<IDeviceTag>, Partial<IDeviceTag>>({
      query: (tag) => ({
        url: '/hardware/tags',
        method: 'POST',
        body: { tag },
      }),
      invalidatesTags: ['DeviceTag'],
    }),
    updateTag: builder.mutation<void, Pick<IDeviceTag, 'id'> & Partial<IDeviceTag>>({
      query: ({ id, ...tag }) => ({
        url: `/hardware/tags/${id}`,
        method: 'PUT',
        body: { tag },
      }),
      invalidatesTags: ['DeviceTag'],
    }),
    deleteTag: builder.mutation<IIdResponse, number>({
      query: (id) => ({ url: `/hardware/tags/${id}`, method: 'DELETE' }),
      invalidatesTags: ['DeviceTag'],
    }),
  }),
});

export const { useGetTagsQuery, useCreateTagMutation, useUpdateTagMutation, useDeleteTagMutation } =
  tagsApi;
