import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardTitle } from '@smart-kasa/ui';

import type { IDevice } from 'services/api/tms/devices/types';
import { useGetPosTerminalsQuery } from 'services/api/tms/terminals/api';
import { TerminalTable } from 'features/pos/components/TerminalTable';
import { ROUTES } from 'routes';

export type DeviceTerminalsProps = {
  device: IDevice;
};

export const DeviceTerminals = ({ device }: DeviceTerminalsProps) => {
  const navigate = useNavigate();
  const { data: terminals, isLoading } = useGetPosTerminalsQuery({ deviceId: device.id });

  return (
    <Fragment>
      <CardTitle>Список підключених мерчантів</CardTitle>

      <TerminalTable
        data={terminals?.data}
        isLoading={isLoading}
        onRowClick={(item) => navigate(ROUTES.terminalShow(item.id))}
      />
    </Fragment>
  );
};
