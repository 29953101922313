import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateCustomerMutation } from 'services/api/tms/customers/api';

import { CustomerForm } from '../../components/CustomerForm';
import styles from './CustomerCreate.module.scss';

export const CustomerCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateCustomerMutation();

  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(({ data: customer }) => {
          navigate(ROUTES.customerShow(customer.id));
        });
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <div className={styles.card}>
        <PageHeader>Додання нового клієнта</PageHeader>
        <CustomerForm defaultValues={{}} onSubmit={handleSubmit} />
      </div>
    </Fragment>
  );
};
