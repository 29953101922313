import { useEffect, useState } from 'react';

export enum SCRIPT_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
}

type UseScriptOptions = {
  async?: boolean;
  id?: string;
};

type UseScriptResult = {
  status: string;
  isLoading: boolean;
  isSuccessful: boolean;
};

export const useScript = (src: string, options?: UseScriptOptions): UseScriptResult => {
  const [status, setStatus] = useState<SCRIPT_STATUS>(
    src ? SCRIPT_STATUS.LOADING : SCRIPT_STATUS.IDLE
  );

  useEffect(() => {
    if (!src) {
      setStatus(SCRIPT_STATUS.IDLE);

      return;
    }

    let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;

    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = (options && options.async) || true;
      if (options && options.id) script.id = options.id;
      script.setAttribute('data-status', SCRIPT_STATUS.LOADING);
      document.body.appendChild(script);

      const setAttributeFromEvent = (event) => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? SCRIPT_STATUS.READY : SCRIPT_STATUS.ERROR
        );
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status') as SCRIPT_STATUS);
    }

    const setStateFromEvent = (event) => {
      setStatus(event.type === 'load' ? SCRIPT_STATUS.READY : SCRIPT_STATUS.ERROR);
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src, options]);

  return {
    status,
    isLoading: status === SCRIPT_STATUS.LOADING,
    isSuccessful: status === SCRIPT_STATUS.READY,
  };
};
