import { FC, memo } from 'react';
import {
  Card,
  Collapse,
  CollapseContent,
  CollapseHeader,
  Icon,
  Inline,
  InlineSpace,
  Strong,
} from '@smart-kasa/ui';

import { MapFilterForm, IMapFilterForm } from './MapFilterForm';
import styles from './MapFilter.module.scss';

export type MapFilterProps = IMapFilterForm;

export const MapFilter: FC<MapFilterProps> = memo((props) => (
  <Card className={styles.filter}>
    <Collapse theme="transparent" active>
      <CollapseHeader>
        <Inline className={styles.toggler}>
          <Icon name="filter" />
          <InlineSpace />
          <Strong>Фільтрація</Strong>
        </Inline>
      </CollapseHeader>

      <CollapseContent className={styles.content}>
        <MapFilterForm {...props} />
      </CollapseContent>
    </Collapse>
  </Card>
));
