import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  Hint,
  IDataTableColumn,
  Icon,
  InlineSpace,
  PageHeader,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { AttributeTable, DateView } from 'components';
import { useDeleteTagMutation, useGetTagsQuery } from 'services/api/tms/tags/api';
import type { IDeviceTag } from 'services/api/tms/tags/types';

export const TagList = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { data: tags, isLoading } = useGetTagsQuery();
  const [destroy] = useDeleteTagMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити категорію?');

    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IDeviceTag>[] = [
    {
      key: 'id',
      title: 'ID',
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: 'Назва',
    },
    {
      key: 'description',
      title: 'Опис',
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (lastOnlineAt) => <DateView value={lastOnlineAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: (id) => (
        <Fragment>
          <ButtonIcon>
            <Icon size="large" name="edit" />
          </ButtonIcon>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);
              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button
            variant="outlined"
            color="dark"
            onClick={() => navigate(ROUTES.hardwareTagCreate())}
          >
            Додати категорію
          </Button>
        }
      >
        Список категорій
      </PageHeader>

      <AttributeTable
        data={tags?.data}
        isLoading={isLoading}
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.hardwareTagEdit(item.id))}
      />

      <Outlet />
    </Fragment>
  );
};
