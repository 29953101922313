import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PageHeader,
  Pagination,
  IDataTableColumn,
  ButtonIcon,
  Icon,
  ProgressBar,
  Hint,
  Tooltip,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useQuery } from 'hooks/useQuery';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { AttributeTable, DateView } from 'components';
import { useGetTasksQuery } from 'services/api/tms/tasks/api';
import type { ITask } from 'services/api/tms/tasks/types';

import { TaskStatus } from '../../components/TaskStatus';
import styles from './TaskList.module.scss';

export const TaskList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: tasks, isLoading } = useGetTasksQuery(query);

  const columns: IDataTableColumn<ITask>[] = [
    {
      key: 'id',
      title: 'ID',
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      title: 'Назва',
    },
    {
      key: 'taskType',
      title: 'Тип',
    },
    {
      key: 'user',
      title: 'Користувач',
      render: (user, { createdAt }) => (
        <Fragment>
          {user?.name}
          <Hint margin={0}>
            <DateView value={createdAt} />
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'stats',
      title: (
        <Tooltip text="Всього/виконано/скасовано" width={200}>
          Статус
        </Tooltip>
      ),

      render: (stats) => {
        const progress = ((stats.successCount + stats.failureCount) / stats.totalCount) * 100;

        return (
          <Fragment>
            <ProgressBar percent={progress} />

            <div className={styles.stats}>
              <span className={styles.statsTotal}>{stats.totalCount}</span>
              <span className={styles.statsSuccess}>{stats.successCount}</span>
              <span className={styles.statsFailure}>{stats.failureCount}</span>
            </div>
          </Fragment>
        );
      },
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state, { completedAt }) => (
        <Fragment>
          <TaskStatus state={state} />
          {completedAt && (
            <Hint>
              <DateView value={completedAt} />
            </Hint>
          )}
        </Fragment>
      ),
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader>Групові завдання</PageHeader>

      <AttributeTable
        rowIdKey="id"
        columns={columns}
        emptyText="Список порожній"
        data={tasks?.data}
        isLoading={isLoading}
        onRowClick={(task) => navigate(ROUTES.taskShow(task.id))}
      />

      <Pagination
        totalItems={tasks?.meta?.totalCount}
        currentPage={tasks?.meta?.currentPage}
        perPage={tasks?.meta?.limitValue}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
