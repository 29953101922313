import { useGetRolesQuery } from 'services/api/tms/roles/api';
import type { IRole } from 'services/api/tms/roles/types';

type UseRolesResult = {
  roles: IRole[];
  isLoading: boolean;
  refresh: () => void;
};

export const useRoles = (): UseRolesResult => {
  const { roles, refetch, isFetching } = useGetRolesQuery(undefined, {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, roles: response?.data ?? [] }),
  });

  return { roles, refresh: refetch, isLoading: isFetching };
};
