import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IGateway } from 'services/api/tms/gateways/types';

import { GatewayFormSchema } from './GatewayFormSchema';

export interface IGatewayForm {
  defaultValues: Partial<IGateway>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const GatewayForm: FC<IGatewayForm> = ({ defaultValues, onCancel, onSubmit }) => (
  <Form<any>
    validate={yupResolver(GatewayFormSchema)}
    initialValues={defaultValues}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col span={12}>
            <Field.Input name="environment" label="Середовище" />

            <Row gutter={10}>
              <Col span={12}>
                <Field.Number name="connectionTimeout" label="Час очікування підключення" />

                <Field.Switch name="isPrimary" label="Використовувати як основний?" />
              </Col>
              <Col span={12}>
                <Field.Number name="requestTimeout" label="Час очікування відповіді" />
                <Field.Switch name="useSSL" label="Використовувати SSL" />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={18}>
                <Field.Input name="host" label="IP адреса" />
              </Col>
              <Col span={6}>
                <Field.Input name="port" label="Порт" />
              </Col>
            </Row>

            <Field.TextArea name="description" label="Короткий опис" rows={5} />
          </Col>
        </Row>

        <FormElement direction="row" justifyContent="flex-end">
          <Button variant="text" color="dark" onClick={onCancel}>
            Скасувати
          </Button>
          <Button width="narrow" color="dark" type="submit">
            Зберегти
          </Button>
        </FormElement>
      </form>
    )}
  </Form>
);
