import { ReactNode } from 'react';

import styles from './InfoRow.module.scss';

export type InfoRowProps = {
  name: ReactNode;
  children?: ReactNode;
};

export const InfoRow = ({ name, children }: InfoRowProps) => (
  <div className={styles.row}>
    <div className={styles.key}>{name}</div>
    <pre className={styles.value}>{children}</pre>
  </div>
);
