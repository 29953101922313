import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IDeviceModel } from 'services/api/tms/models/types';
import { useDeviceVendors } from 'features/hardware/routes/Vendors/hooks/useDeviceVendors';

import { ModelFormSchema } from './ModelFormSchema';

export interface IModelForm {
  defaultValues: Partial<IDeviceModel>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const ModelForm: FC<IModelForm> = ({ defaultValues, onCancel, onSubmit }) => {
  const { vendors } = useDeviceVendors();

  return (
    <Form<any>
      validate={yupResolver(ModelFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={8}>
              <Field.Uploader name="photo" accept=".png, .jpg, .jpeg" />
            </Col>
            <Col span={16}>
              <Field.Input name="name" label="Назва" required />
              <Field.Select
                name="vendorId"
                placeholder="Оберіть постачальника"
                label="Постачальник"
                options={vendors}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                required
              />
            </Col>
          </Row>

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="dark" onClick={onCancel}>
              Скасувати
            </Button>
            <Button width="narrow" color="dark" type="submit">
              Зберегти
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
