import { Routes, Route } from 'react-router-dom';

import { ModalLayout } from 'layouts/ModalLayout';

import { ProfileShow } from './routes/ProfileShow';
import { ProfileEdit } from './routes/ProfileEdit';
import { ProfilePassword } from './routes/ProfilePassword';

export const Profile = () => (
  <Routes>
    <Route element={<ProfileShow />}>
      <Route index element={null} />
      <Route element={<ModalLayout />}>
        <Route path="edit" element={<ProfileEdit />} />
        <Route path="password" element={<ProfilePassword />} />
      </Route>
    </Route>
  </Routes>
);
