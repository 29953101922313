export const ROUTES = {
  root: () => `/`,

  // Auth
  auth: () => '/auth',
  authLogin: () => '/auth/sign-in',
  authRegistration: (token: string = ':token') => `/auth/sign-up/${token}`,
  authPasswordRecovery: () => '/auth/password-recovery',

  // Dashboard
  dashboard: () => '/dashboard',

  // AppStore
  appStore: () => '/appstore',
  appStoreCreate: () => '/appstore/new',
  appStoreShow: (id: string = ':id') => `/appstore/${id}`,

  // Hardware
  hardware: () => '/hardware/*',

  // Devices
  hardwareDevices: () => '/hardware/devices',
  hardwareDevicesByTag: (tag: number | string = ':tag') => `/hardware/devices/tags/${tag}`,
  hardwareDevicesTagCreate: () => '/hardware/devices/tags/new',
  hardwareDeviceCreate: () => '/hardware/devices/new',
  hardwareDeviceShow: (id: string = ':id') => `/hardware/devices/${id}`,
  hardwareDeviceEdit: (id: string = ':id') => `/hardware/devices/${id}/edit`,
  hardwareDeviceDelete: (id: string = ':id') => `/hardware/devices/${id}/delete`,

  // Tags
  hardwareTags: () => '/hardware/tags',
  hardwareTagCreate: () => '/hardware/tags/new',
  hardwareTagEdit: (id: number | string = ':id') => `/hardware/tags/${id}/edit`,
  hardwareTagDelete: (id: number | string = ':id') => `/hardware/tags/${id}/delete`,

  // Vendors
  hardwareVendors: () => '/hardware/vendors',
  hardwareVendorCreate: () => '/hardware/vendors/new',
  hardwareVendorEdit: (id: number | string = ':id') => `/hardware/vendors/${id}/edit`,
  hardwareVendorDelete: (id: number | string = ':id') => `/hardware/vendors/${id}/delete`,

  // Models
  hardwareModels: () => '/hardware/models',
  hardwareModelCreate: () => '/hardware/models/new',
  hardwareModelEdit: (id: number | string = ':id') => `/hardware/models/${id}/edit`,
  hardwareModelDelete: (id: number | string = ':id') => `/hardware/models/${id}/delete`,

  // Banks
  banks: () => '/banks',
  bankCreate: () => '/banks/new',
  bankShow: (bankId: number | string = ':bankId') => `/banks/${bankId}`,

  // Gateways
  bankGateways: (bankId: number | string = ':bankId') => `/banks/${bankId}/gateways`,
  bankGatewayCreate: (bankId: number | string = ':bankId') => `/banks/${bankId}/gateways/new`,
  bankGatewayEdit: (bankId: number | string = ':bankId', id: number | string = ':id') =>
    `/banks/${bankId}/gateways/${id}`,

  bankTerminals: (bankId: number | string = ':bankId') => `/banks/${bankId}/terminals`,
  bankEdit: (bankId: number | string = ':bankId') => `/banks/${bankId}/edit`,
  bankDelete: (bankId: number | string = ':bankId') => `/banks/${bankId}/delete`,

  // Map
  map: () => '/map',

  // Customers
  customers: () => '/customers',
  customerCreate: () => '/customers/new',
  customerShow: (id: string = ':id') => `/customers/${id}`,
  customerEdit: (id: string = ':id') => `/customers/${id}/edit`,
  customerDelete: (id: string = ':id') => `/customers/${id}/delete`,

  // Terminals
  terminals: () => '/terminals',
  terminalCreate: () => '/terminals/new',
  terminalShow: (id: string = ':id') => `/terminals/${id}`,

  // Users
  users: () => '/users',
  userCreate: () => '/users/new',
  userEdit: (id: number | string = ':id') => `/users/${id}/edit`,

  // Profile
  profile: () => '/profile',
  profileEdit: () => '/profile/edit',
  profilePassword: () => '/profile/password',

  // Events
  events: () => '/events',
  eventShow: (id: number | string = ':id') => `/events/${id}`,

  // Tasks
  tasks: () => '/tasks',
  taskShow: (id: number | string = ':id') => `/tasks/${id}`,

  // Transitions
  transitions: () => '/transitions',

  // Hub
  hub: () => '/hub',
  hubAlerts: () => '/hub/alerts',

  // Settings
  settings: () => '/settings',
};
