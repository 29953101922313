import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, FormElement } from '@smart-kasa/ui';

import { yupResolver } from 'utils/yupResolver';
import { SettingsFormSchema } from './SettingsFormSchema';

interface ISettingsForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const SettingsForm: FC<ISettingsForm> = ({ defaultValues, onSubmit }) => (
  <Form<any>
    validate={yupResolver(SettingsFormSchema)}
    initialValues={defaultValues}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormElement direction="row" justifyContent="flex-end">
          <Button width="narrow" color="dark" type="submit">
            Зберегти
          </Button>
        </FormElement>
      </form>
    )}
  </Form>
);
