import { FC, HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cs from 'classnames';

import styles from './InlineLink.module.scss';

export type InlineLinkProps = HTMLAttributes<HTMLElement>;
export type InlineRouteLinkProps = LinkProps;

export const InlineLink: FC<InlineLinkProps> = ({ className, children, ...rest }) => (
  <a className={cs(styles.link, className)} {...rest}>
    {children}
  </a>
);

export const InlineRouteLink: FC<InlineRouteLinkProps> = ({ className, ...rest }) => (
  <Link className={cs(styles.link, className)} {...rest} />
);
