import { FC } from 'react';
import { ButtonIcon, Hint, IDataTableColumn, IDataTableProps, Icon } from '@smart-kasa/ui';

import { AttributeTable, DateView } from 'components';
import type { IDevice } from 'services/api/tms/devices/types';

import { DeviceStatus } from '../DeviceStatus';
import styles from './DeviceTable.module.scss';

export interface IDeviceTable
  extends Omit<IDataTableProps<IDevice, 'id'>, 'rowIdKey' | 'columns'> {}

export const DeviceTable: FC<IDeviceTable> = (props) => {
  const columns: IDataTableColumn<IDevice>[] = [
    {
      key: 'serialNumber',
      title: 'Серійний номер',
    },
    {
      key: 'state',
      title: 'Статус',
      render: (state) => <DeviceStatus state={state} />,
    },
    {
      key: 'lastOnlineAt',
      title: 'Останній раз був в мережі',
      render: (lastOnlineAt) => <DateView value={lastOnlineAt} />,
    },
    {
      key: 'note',
      title: 'Примітки',
      render: (note) => <Hint margin={0}>{note}</Hint>,
    },
    {
      key: 'id',
      title: 'Дії',
      render: () => (
        <ButtonIcon className={styles.button}>
          <Icon size="large" name="eye" />
        </ButtonIcon>
      ),
    },
  ];

  return <AttributeTable rowIdKey="id" columns={columns} emptyText="Список порожній" {...props} />;
};
