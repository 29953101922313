export enum DeviceState {
  PENDING = 0,
  ACTIVE = 1,
  BLOCKED = 2,
}

export enum NetworkState {
  OFFLINE = 0,
  ONLINE = 1,
  AWAY = 2,
}
