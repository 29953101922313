import { Tag } from '@smart-kasa/ui';

import { DeviceState } from 'services/api/tms/devices/enums';

const STATE_NAME: Record<DeviceState, { name: string; color: any }> = {
  [DeviceState.PENDING]: {
    name: 'Очікує активації',
    color: 'grey',
  },
  [DeviceState.ACTIVE]: {
    name: 'Активний',
    color: 'green',
  },
  [DeviceState.BLOCKED]: {
    name: 'Неактивний',
    color: 'orange',
  },
};

export type DeviceStatusProps = {
  state: DeviceState;
  children?: never;
};

export const DeviceStatus = ({ state }: DeviceStatusProps) => {
  const status = STATE_NAME[state];

  return <Tag color={status.color}>{status.name}</Tag>;
};
