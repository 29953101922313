import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, ButtonText, FormElement, InlineSpace } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { Field, Error } from 'components';
import { yupResolver } from 'utils/yupResolver';

import { LoginFormSchema } from './LoginFormSchema';

interface ILoginForm {
  defaultValues: any;
  onSubmit: (fd: any) => void;
}

export const LoginForm: FC<ILoginForm> = ({ defaultValues, onSubmit }) => {
  const navigate = useNavigate();

  return (
    <Form<any>
      validate={yupResolver(LoginFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitErrors }) => (
        <form onSubmit={handleSubmit}>
          <Field.Input name="email" label="Електронна пошта" />
          <Field.Password name="password" label="Пароль" type="password" />

          {submitErrors && <Error>{submitErrors.base}</Error>}

          <FormElement alignItems="center">
            <Button type="submit" color="orange" width="full">
              <FormattedMessage id="auth.buttons.login" />
            </Button>
            <InlineSpace direction="vertical" />
            <ButtonText onClick={() => navigate(ROUTES.authPasswordRecovery())}>
              <FormattedMessage id="auth.navigation.forgot-password" />
            </ButtonText>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
