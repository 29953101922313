import { createContext, useReducer } from 'react';
import { IConfirmState, initialState, reducer } from './reducer';
import { ConfirmDialog } from './ConfirmDialog';

export interface IConfirmContext extends Array<any> {
  state?: IConfirmState;
  dispatch?: any;
}

export const ConfirmContext = createContext<IConfirmContext>([]);

export const ConfirmContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfirmContext.Provider value={[state, dispatch]}>
      {children}

      <ConfirmDialog />
    </ConfirmContext.Provider>
  );
};
