import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IUser } from 'services/api/tms/users/types';

import { useRoles } from 'features/roles/hooks/useRoles';
import { UserFormSchema } from './UserFormSchema';

interface IUserForm {
  defaultValues: Partial<IUser>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const UserForm: FC<IUserForm> = ({ defaultValues, onCancel, onSubmit }) => {
  const { roles } = useRoles();

  return (
    <Form<any>
      validate={yupResolver(UserFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={6}>
              <Field.Uploader name="photo" />
            </Col>
            <Col span={18}>
              <Row gutter={10}>
                <Col span={12}>
                  <Field.Input name="name" label="ПІБ" />
                  <Field.Input name="email" label="Email" />
                </Col>
                <Col span={12}>
                  <Field.Select
                    name="roleId"
                    placeholder="Оберіть зі списку"
                    label="Права доступу "
                    options={roles}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                  />
                  <Field.Input name="phoneNumber" label="Номер телефону" />
                </Col>
              </Row>
            </Col>
          </Row>

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="dark" onClick={onCancel}>
              Скасувати
            </Button>
            <Button color="dark" type="submit">
              Зберегти
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
