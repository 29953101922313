import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useFindDeviceQuery, useUpdateDeviceMutation } from 'services/api/tms/devices/api';

import { DeviceEditForm } from '../../components/DeviceEditForm';

export const DeviceEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: device } = useFindDeviceQuery(String(id));
  const [update] = useUpdateDeviceMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareDeviceShow(id));
  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareDeviceShow(id)));
    },
    [id, update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування пристрою</PageHeader>

      {device ? (
        <DeviceEditForm
          defaultValues={device?.data}
          onCancel={handleDismiss}
          onSubmit={handleSubmit}
        />
      ) : (
        <Preloader />
      )}
    </Fragment>
  );
};
