import { tmsApi } from '../api';
import type { CoreResponse, IIdResponse } from 'types';
import type { IDeviceModel } from './types';

export const modelsApi = tmsApi.enhanceEndpoints({ addTagTypes: ['DeviceModel'] }).injectEndpoints({
  endpoints: (builder) => ({
    getDeviceModels: builder.query<CoreResponse<IDeviceModel[]>, void>({
      query: () => ({ url: '/hardware/models' }),
      providesTags: ['DeviceModel'],
    }),
    createDeviceModel: builder.mutation<CoreResponse<IDeviceModel>, Partial<IDeviceModel>>({
      query: (model) => ({
        url: '/hardware/models',
        method: 'POST',
        body: { model },
      }),
      invalidatesTags: ['DeviceModel'],
    }),
    updateDeviceModel: builder.mutation<void, Pick<IDeviceModel, 'id'> & Partial<IDeviceModel>>({
      query: ({ id, ...model }) => ({
        url: `/hardware/models/${id}`,
        method: 'PUT',
        body: { model },
      }),
      invalidatesTags: ['DeviceModel'],
    }),
    deleteDeviceModel: builder.mutation<IIdResponse, number>({
      query: (id) => ({
        url: `/hardware/models/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceModel'],
    }),
  }),
});

export const {
  useGetDeviceModelsQuery,
  useCreateDeviceModelMutation,
  useUpdateDeviceModelMutation,
  useDeleteDeviceModelMutation,
} = modelsApi;
