import { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CardTitle, Hint, InlineSpace, Input, Row } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useDeleteBankMutation } from 'services/api/tms/banks/api';

export const BankDelete = () => {
  const { bankId } = useParams();
  const navigate = useNavigate();
  const [destroy] = useDeleteBankMutation();
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const handleConfirm = (event: ChangeEvent<HTMLInputElement>) =>
    setConfirmed(event.target.value === 'delete');

  const handleDelete = useCallback(() => {
    destroy(String(bankId))
      .unwrap()
      .then(() => navigate(ROUTES.banks()));
  }, [bankId, destroy, navigate]);

  return (
    <Fragment>
      <CardTitle>Ви дійсно бажаєте видалити фінансову установу?</CardTitle>
      <Hint>
        Будь ласка, зверніть увагу, що видалення цього запису може призвести до втрати важливих
        даних, зокрема інформації про підключені пристрої та статистики їх використання. Крім того,
        будуть видалені дані про платіжні шлюзи, що може вплинути на обробку платежів та фінансову
        звітність.
      </Hint>

      <Hint>
        Повідомляємо також, що після видалення запису статистика, яка була накопичена, більше не
        буде доступна для перегляду або аналізу. Будь ласка, переконайтеся, що ви дійсно бажаєте
        продовжити цю операцію, оскільки вона є незворотньою.
      </Hint>

      <InlineSpace direction="vertical" size="medium" />

      <Hint>
        Будь ласка, для підтвердження видалення введіть текст <strong>delete</strong> в поле нижче.
      </Hint>
      <Row>
        <Input onChange={handleConfirm} />
        <InlineSpace />
        <Button onClick={handleDelete} color="danger" width="narrow" disabled={!confirmed}>
          Підтвердити
        </Button>
      </Row>
    </Fragment>
  );
};
