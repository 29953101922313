import { Col, Row } from '@smart-kasa/ui';
import { Description, Panel } from 'components';

export const BankDashboard = () => (
  <>
    <Row gutter={10}>
      <Col span={8}>
        <Panel>
          <Description label="Всього підключено пристроїв">1992</Description>
        </Panel>
      </Col>
      <Col span={8}>
        <Panel>
          <Description label="Нових підключень за місяць">5</Description>
        </Panel>
      </Col>
      <Col span={8}>
        <Panel>
          <Description label="Оборот за місяць">-</Description>
        </Panel>
      </Col>
    </Row>
  </>
);
