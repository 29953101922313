import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  IDataTableColumn,
  Icon,
  InlineSpace,
  PageHeader,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { AttributeTable, DateView, Picture } from 'components';
import type { IDeviceModel } from 'services/api/tms/models/types';
import { useDeleteDeviceModelMutation } from 'services/api/tms/models/api';

import { useDeviceModels } from '../../hooks/useDeviceModels';

export const ModelList = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { models, isLoading } = useDeviceModels();
  const [destroy] = useDeleteDeviceModelMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити модель?');

    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IDeviceModel>[] = [
    {
      key: 'photo',
      width: 70,
      render: (photo) => <Picture src={photo} icon="smartKasa" size="M" fill="grey" />,
    },
    {
      key: 'name',
      width: '30%',
      title: 'Назва',
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: (id) => (
        <Fragment>
          <ButtonIcon>
            <Icon size="large" name="edit" />
          </ButtonIcon>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);
              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button
            variant="outlined"
            color="dark"
            onClick={() => navigate(ROUTES.hardwareModelCreate())}
          >
            Додати модель
          </Button>
        }
      >
        Список моделей
      </PageHeader>

      <AttributeTable
        rowIdKey="id"
        data={models}
        isLoading={isLoading}
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.hardwareModelEdit(item.id))}
      />

      <Outlet />
    </Fragment>
  );
};
