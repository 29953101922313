import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateDeviceMutation } from 'services/api/tms/devices/api';

import { DeviceForm } from '../../components/DeviceForm';

export const DeviceCreate = () => {
  const navigate = useNavigate();
  const [create] = useCreateDeviceMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareDevices());
  const handleSubmit = useCallback(
    (values) => {
      create(values)
        .unwrap()
        .then(() => navigate(ROUTES.hardwareDevices()));
    },
    [create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Додання нового обладнання</PageHeader>

      <DeviceForm defaultValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
