import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import { Button, Col, Divider, FormElement, FormGroup, Label, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import { fromEnumToOptions } from 'utils/fromEnum';
import { BusinessEntityType } from 'services/api/tms/customers/enums';
import { useLazyGetCustomersQuery } from 'services/api/tms/customers/api';
import { useLazyGetDevicesQuery } from 'services/api/tms/devices/api';
import { useLazyGetMerchantsQuery } from 'services/api/tms/merchants/api';
import { useBanks } from 'features/banks/hooks/useBanks';

import { TerminalFormSchema } from './TerminalFormSchema';
import styles from './TerminalForm.module.scss';
import { PlacesAutocomplete } from 'components/PlacesAutocomplete';
import { GooglePlacesContext } from 'contexts/GooglePlacesContext';

interface ITerminalForm {
  defaultValues: any;
  onSubmit: (fd) => void;
}

export const TerminalForm: FC<ITerminalForm> = ({ defaultValues, onSubmit }) => {
  const { formatMessage } = useIntl();
  const { banks } = useBanks();
  const [loadCustomers] = useLazyGetCustomersQuery();
  const [loadDevices, { isLoading: isDevicesLoading }] = useLazyGetDevicesQuery();
  const [loadMerchants] = useLazyGetMerchantsQuery();
  const { isSuccessful } = useContext(GooglePlacesContext);

  const fetchCustomer = async (query: string) => {
    const { data: customers } = await loadCustomers({ query }).unwrap();

    return customers[0];
  };

  const fetchDevices = async (term: string) => {
    const { data: devices } = await loadDevices({ serialNumber: term }).unwrap();

    return devices;
  };

  const fetchMerchant = async (registrationNumber: string, bankId: string, mid: string) => {
    const { data: merchants } = await loadMerchants({ registrationNumber, bankId, mid }).unwrap();

    return merchants[0];
  };

  return (
    <Form<any>
      validate={yupResolver(TerminalFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, form, values }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormGroup>
            <Row gutter={10}>
              <Col span={8}>
                <Field.Input
                  name="customer.registrationNumber"
                  label="ІПН/ЄДРПОУ"
                  placeholder="XXXXXXXXXX"
                  required
                  onChange={async (event) => {
                    const registrationNumber = event.target.value;

                    form.change('customer.registrationNumber', registrationNumber);

                    const customer = await fetchCustomer(event.target.value);

                    if (customer) {
                      form.change('customer', { ...customer, registrationNumber });
                    }
                  }}
                />
              </Col>
              <Col span={8}>
                <Field.Input
                  name="customer.organizationName"
                  label="Назва торговця/організації"
                  disabled={values.customer?.id}
                  required
                />
              </Col>
              <Col span={8}>
                <Field.Select
                  name="customer.businessEntityType"
                  placeholder="Оберіть зі списку"
                  label="Форма власності"
                  options={fromEnumToOptions(BusinessEntityType)}
                  disabled={values.customer?.id}
                  isClearable
                />
              </Col>
            </Row>
          </FormGroup>

          <Divider type="horizontal" />

          <FormGroup>
            <Row gutter={10}>
              <Col span={8}>
                <Field.Select
                  name="merchant.bankId"
                  label="Банк-еквайер"
                  placeholder="Оберіть банк"
                  options={banks}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  disabled={!values.customer?.registrationNumber}
                  required
                />
              </Col>
              <Col span={8} className={styles.fieldGroup}>
                <Field.Input
                  name="merchant.mid"
                  label="MID"
                  placeholder="Merchant ID"
                  disabled={!values.merchant?.bankId}
                  required
                  onChange={async (event) => {
                    const mid = event.target.value;

                    form.change('merchant.mid', mid);

                    const merchant = await fetchMerchant(
                      values.customer.registrationNumber,
                      values.merchant.bankId,
                      mid
                    );

                    if (merchant) {
                      form.change('merchant', { ...merchant, mid });
                    }
                  }}
                />
                <Field.Input
                  name="terminal.tid"
                  label="TID"
                  placeholder="Terminal ID"
                  disabled={!values.merchant?.mid}
                  required
                />
              </Col>
              <Col span={8}>
                <Field.AsyncSelect
                  name="terminal.deviceId"
                  label="Серійний номер"
                  placeholder="WPY/PP"
                  isClearable
                  isLoading={isDevicesLoading}
                  loadOptions={fetchDevices}
                  disabled={!values.terminal?.tid}
                  getOptionLabel={(option) => option?.serialNumber}
                  getOptionValue={(option) => option?.id}
                />
              </Col>
            </Row>
          </FormGroup>

          <Divider type="horizontal" />

          <FormGroup>
            <Row gutter={10}>
              <Col span={8}>
                <Field.Input
                  name="merchant.name"
                  label="Юридична назва торговця"
                  disabled={!values.merchant?.mid}
                  required
                />
                <Field.Input
                  name="merchant.cityName"
                  label="Місто"
                  placeholder="Введіть місто або населений пункт"
                  disabled={!values.merchant?.mid}
                  required
                />
              </Col>
              <Col span={16}>
                <Field.Input
                  name="merchant.shopName"
                  label="Назва торгової точки"
                  disabled={!values.merchant?.mid}
                  required
                />
                {isSuccessful && (
                  <FormElement label={<Label required>Юридична адреса торговця та індекс</Label>}>
                    <PlacesAutocomplete
                      value={values.merchant?.shopAddress}
                      disabled={!values.merchant?.mid}
                      onChange={({ formatted_address, geometry: { location } }) => {
                        form.batch(() => {
                          form.change('merchant.shopAddress', formatted_address);
                          form.change('merchant.latitude', location.lat());
                          form.change('merchant.longitude', location.lng());
                        });
                      }}
                    />
                  </FormElement>
                )}
              </Col>
            </Row>
          </FormGroup>

          <Divider type="horizontal" />

          <FormGroup>
            <Row gutter={10}>
              <Col span={8}>
                <Field.Input
                  name="merchant.contactName"
                  label="Контактна особа"
                  placeholder="Введіть прізвище та ім'я підписанта"
                  disabled={!values.merchant?.mid}
                />
                <Field.Input
                  name="merchant.contractNumber"
                  label="Номер договору"
                  placeholder="Введіть номер договору"
                  disabled={!values.merchant?.mid}
                />
              </Col>
              <Col span={8}>
                <Field.Input
                  name="contactEmail"
                  label="Email"
                  placeholder="Поштова скринька"
                  disabled={!values.merchant?.mid}
                />
              </Col>
              <Col span={8}>
                <Field.Input
                  name="merchant.contactPhoneNumber"
                  label="Номер телефону"
                  placeholder="+38 (___) ___-__-__"
                  disabled={!values.merchant?.mid}
                />
              </Col>
            </Row>
          </FormGroup>

          <Divider type="horizontal" />

          <FormGroup>
            <Field.TextArea
              name="merchant.description"
              label="Додаткова інформація"
              disabled={!values.merchant?.mid}
            />
          </FormGroup>

          <FormElement direction="row" justifyContent="flex-end">
            <Button width="narrow" color="dark" type="submit">
              {formatMessage({ id: 'buttons.save' })}
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
