import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Icon,
  InlineSpace,
  PageHeader,
  Preloader,
  Row,
} from '@smart-kasa/ui';

import { BackLink, DateView, Description, InfoRow } from 'components';
import { useFindEventQuery } from 'services/api/tms/events/api';

import { EventStatus } from '../../components/EventStatus';
import { EventState } from 'services/api/tms/events/enums';

export const EventShow = () => {
  const { id } = useParams();
  const { event, isLoading } = useFindEventQuery(String(id), {
    selectFromResult: ({ data: response, ...rest }) => ({ ...rest, event: response?.data }),
  });

  const handleReply = () => {
    // TODO
  };

  if (!event || isLoading) return <Preloader />;

  return (
    <Fragment>
      <PageHeader
        actions={
          event.state === EventState.CANCELLED && (
            <Button
              variant="outlined"
              color="dark"
              rightIcon={<Icon name="sync" />}
              onClick={handleReply}
            >
              Повторити
            </Button>
          )
        }
      >
        <BackLink>Перегляд події</BackLink>
      </PageHeader>

      <Divider type="horizontal" />
      <InlineSpace direction="vertical" size={20} />

      <Row gutter={10}>
        <Col span={8}>
          <Description label="Серійний номер">{event.serialNumber}</Description>
        </Col>
        <Col span={8}>
          <Description label="Дата створення">
            <DateView value={event.createdAt} />
          </Description>
        </Col>
        <Col span={8}>
          <Description label="Статус">
            <EventStatus state={event.state} />
          </Description>
        </Col>
      </Row>

      <InlineSpace direction="vertical" size={20} />
      <Divider type="horizontal" />
      <InlineSpace direction="vertical" size={20} />

      <InfoRow name="ID події">{event.id}</InfoRow>
      <InfoRow name="Користувач">{event.user?.name}</InfoRow>
      <InfoRow name="Інструкція">
        {event.namespace}.{event.command}
      </InfoRow>
      {event.completedAt && (
        <InfoRow name="Дата виконання">
          <DateView value={event.completedAt} />
        </InfoRow>
      )}
    </Fragment>
  );
};
