import { tmsApi } from '../api';
import type { CoreResponse, ChartData } from 'types';

export const statsApi = tmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankTerminalStats: builder.query<CoreResponse<ChartData[]>, void>({
      query: () => ({ url: '/stats/terminals' }),
    }),
  }),
});

export const { useGetBankTerminalStatsQuery } = statsApi;
