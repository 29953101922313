import { FC } from 'react';
import { CircleMarker } from 'react-leaflet';

import { Point, PointProps } from './Point';

export type CirclePointProps = PointProps & {
  radius?: number;
};

export const CirclePoint: FC<CirclePointProps> = ({ position, radius = 75, ...rest }) => (
  <CircleMarker center={position} radius={radius} weight={1}>
    <Point position={position} {...rest} />
  </CircleMarker>
);
