import * as yup from 'yup';
import { schemaPassword } from 'utils/sharedSchemas';

export const PasswordRecoveryPhoneSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const PasswordRecoveryPasswordSchema = yup.object().shape({
  newPassword: schemaPassword,
  newPasswordConfirmation: yup
    .string()
    .required("Пароль обов'язкове для заповнення поле")
    .oneOf([yup.ref('newPassword'), null]),
});

export const PasswordRecoveryCodeSchema = yup.object().shape({
  code: yup.string().required(),
});
