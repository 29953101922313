import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, FormElement, PageHeader } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IProfileUpdate } from 'services/api/tms/profile/types';
import { useUpdateProfileMutation } from 'services/api/tms/profile/api';
import { useProfile } from 'features/profile/hooks/useProfile';

import { ProfileSchema, ProfileErrorSchema } from './ProfileEditSchema';

export const ProfileEdit = () => {
  const navigate = useNavigate();
  const [update] = useUpdateProfileMutation();
  const { profile } = useProfile();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values: IProfileUpdate) =>
      update(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => ProfileErrorSchema.get(err)),
    [update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування даних облікового запису</PageHeader>
      <Form<any>
        validate={yupResolver(ProfileSchema)}
        initialValues={profile}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field.Uploader name="photo" />
            <Field.Input name="name" label="ПІБ" />
            <Field.Input name="email" label="Email" />
            <Field.Input name="phoneNumber" label="Номер телефону" />
            <FormElement direction="row" justifyContent="flex-end">
              <Button variant="text" color="dark" onClick={handleDismiss}>
                Скасувати
              </Button>
              <Button width="wide" color="dark" type="submit">
                Зберегти
              </Button>
            </FormElement>
          </form>
        )}
      </Form>
    </Fragment>
  );
};
