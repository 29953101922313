import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useCreateUserInvitationMutation } from 'services/api/tms/invitations/api';

import { UserInviteForm } from '../../components/UserInviteForm';

export const UserCreate = () => {
  const navigate = useNavigate();
  const [invite] = useCreateUserInvitationMutation();

  const handleDismiss = () => navigate(ROUTES.users());
  const handleSubmit = useCallback(
    (values) => {
      invite(values)
        .unwrap()
        .then(() => navigate(ROUTES.users()));
    },
    [invite, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Запрошення користувачів</PageHeader>

      <UserInviteForm defaultValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
