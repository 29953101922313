import { createSelector } from '@reduxjs/toolkit';

import { markersApi } from 'services/api/tms/markers/api';

const selectMarkersState = (state, params) => markersApi.endpoints.getMarkers.select(params)(state);

export const selectMarkers = createSelector(
  selectMarkersState,
  (result) => result?.data?.data ?? []
);
