import { Fragment, useCallback } from 'react';
import { Card, PageHeader } from '@smart-kasa/ui';

import { BackLink } from 'components';
import { TerminalForm } from 'features/pos/components/TerminalForm';

export const TerminalCreate = () => {
  const handleSubmit = useCallback(() => {}, []);

  return (
    <Fragment>
      <BackLink>Повернутись</BackLink>
      <PageHeader>Створення заявки на підключення POS-терміналу</PageHeader>

      <Card>
        <TerminalForm defaultValues={{}} onSubmit={handleSubmit} />
      </Card>
    </Fragment>
  );
};
