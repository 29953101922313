import { FC, useState } from 'react';
import { Input, Icon, IInputProps, ButtonIcon } from '@smart-kasa/ui';

import styles from './PasswordInput.module.scss';

export interface IPasswordInputProps extends Omit<IInputProps, 'type'> {}

export const PasswordInput: FC<IPasswordInputProps> = (props) => {
  const [isSecure, setIsSecure] = useState<boolean>(true);

  return (
    <Input
      {...props}
      type={isSecure ? 'password' : 'text'}
      suffixIcon={
        <ButtonIcon
          variant="text"
          color="dark"
          onClick={() => setIsSecure(!isSecure)}
          className={styles.button}
        >
          <Icon name={isSecure ? 'eye' : 'eyeSlash'} />
        </ButtonIcon>
      }
    />
  );
};
