import React from 'react';
import cs from 'classnames';

import styles from './ButtonGroup.module.scss';

export type ButtonGroupOption = {
  label: React.ReactNode;
  value: any;
};

export interface ButtonGroupProps {
  className?: string;
  options: ButtonGroupOption[];
  value?: any;
  children?: never;
  onChange?: (value: any, option: ButtonGroupOption) => void;
}

export const ButtonGroup = ({ className, options, value, onChange }: ButtonGroupProps) => {
  const selected = options.find((option) => option.value === value);

  return (
    <div className={cs(styles.buttonset, className)}>
      {options.map((option) => (
        <div
          key={option.value}
          className={cs(styles.option, {
            [styles.active]: selected?.value === option.value,
          })}
          onClick={() => onChange && onChange(option.value, option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
