import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, FormElement, Row } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IUser } from 'services/api/tms/users/types';

import { useRoles } from 'features/roles/hooks/useRoles';
import { UserInviteFormSchema } from './UserInviteFormSchema';

interface IUserInviteForm {
  defaultValues: Partial<IUser>;
  onCancel?: () => void;
  onSubmit: (fd) => void;
}

export const UserInviteForm: FC<IUserInviteForm> = ({ defaultValues, onCancel, onSubmit }) => {
  const { roles } = useRoles();

  return (
    <Form<any>
      validate={yupResolver(UserInviteFormSchema)}
      initialValues={defaultValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Field.Input name="recipientEmail" label="Email" />
            </Col>
            <Col span={12}>
              <Field.Select
                name="roleId"
                placeholder="Оберіть зі списку"
                label="Права доступу"
                options={roles}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
              />
            </Col>
          </Row>

          <FormElement direction="row" justifyContent="flex-end">
            <Button variant="text" color="dark" onClick={onCancel}>
              Скасувати
            </Button>
            <Button color="dark" type="submit">
              Запросити
            </Button>
          </FormElement>
        </form>
      )}
    </Form>
  );
};
