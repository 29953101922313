import { useCallback } from 'react';
import { useQuery } from 'hooks/useQuery';

export const useQueryPagination = () => {
  const { query: matches, push } = useQuery();

  const onSortChange = useCallback(
    ({ sortColumn, sortMode }: any) => {
      push(
        sortColumn && sortMode
          ? {
              ...matches,
              sortColumn,
              sortMode,
            }
          : { ...matches, sortColumn: undefined }
      );
    },
    [matches, push]
  );

  const onPageChange = useCallback(
    ({ page, perPage }) => {
      push({ ...matches, page, perPage: perPage });
    },
    [matches, push]
  );

  return { onSortChange, onPageChange };
};
