import { DateView, Picture } from 'components';
import type { IApplicationPackage } from 'services/api/tms/apps/types';

import styles from './AppCard.module.scss';

export type AppCardPropd = {
  app: IApplicationPackage;
  onOpen?: (app: IApplicationPackage) => void;
};

export const AppCard = ({ app, onOpen }: AppCardPropd) => {
  return (
    <div className={styles.card} onClick={() => onOpen && onOpen(app)}>
      <Picture src={app?.logo} icon="shopApps" size="M" fill="grey" />
      {app.name}
      {app.packageName}
      <DateView value={app.updatedAt} />
    </div>
  );
};
