import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@smart-kasa/ui';

import { useCreateGatewayMutation } from 'services/api/tms/gateways/api';

import { GatewayForm } from '../../components/GatewayForm';

export const GatewayCreate = () => {
  const { bankId } = useParams();
  const navigate = useNavigate();
  const [create] = useCreateGatewayMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values) => {
      create({ ...values, bankId })
        .unwrap()
        .then(() => navigate(-1));
    },
    [bankId, create, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Додання нового платіжного шлюзу</PageHeader>
      <GatewayForm defaultValues={{}} onCancel={handleDismiss} onSubmit={handleSubmit} />
    </Fragment>
  );
};
