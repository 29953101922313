import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, PageHeader, Pagination } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useQuery } from 'hooks/useQuery';
import { useGetPosTerminalsQuery } from 'services/api/tms/terminals/api';
import { TerminalTable } from 'features/pos/components/TerminalTable';

export const TerminalList = () => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { onPageChange } = useQueryPagination();
  const { data: terminals, isLoading } = useGetPosTerminalsQuery(query);

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button variant="outlined" color="dark" onClick={() => navigate(ROUTES.terminalCreate())}>
            Створення заявки
          </Button>
        }
      >
        POS-термінали
      </PageHeader>

      <TerminalTable
        data={terminals?.data}
        isLoading={isLoading}
        onRowClick={(item) => navigate(ROUTES.terminalShow(item.id))}
      />

      <Pagination
        totalItems={terminals?.meta?.totalCount}
        currentPage={terminals?.meta?.currentPage}
        perPage={terminals?.meta?.limitValue}
        onPageChange={onPageChange}
      />

      <Outlet />
    </Fragment>
  );
};
