import { Outlet, useNavigate } from 'react-router-dom';
import { Modal } from '@smart-kasa/ui';

import styles from './ModalLayout.module.scss';

export const ModalLayout = () => {
  const navigate = useNavigate();
  const handleDismiss = () => navigate(-1);

  return (
    <Modal className={styles.modal} onClose={handleDismiss}>
      <Outlet />
    </Modal>
  );
};
