import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Alert, Button, Col, FormElement, InlineSpace, Row, Title } from '@smart-kasa/ui';

import { Field } from 'components';
import { yupResolver } from 'utils/yupResolver';
import type { IProfileChangePassword } from 'services/api/tms/profile/types';
import { useChangeProfilePasswordMutation } from 'services/api/tms/profile/api';

import { ProfilePasswordErrorSchema, ProfilePasswordSchema } from './ProfilePasswordSchema';
import styles from './ProfilePassword.module.scss';

export const ProfilePassword = () => {
  const navigate = useNavigate();
  const [update] = useChangeProfilePasswordMutation();

  const handleDismiss = () => navigate(-1);
  const handleSubmit = useCallback(
    (values: IProfileChangePassword) =>
      update(values)
        .unwrap()
        .then(() => navigate(-1))
        .catch((err) => ProfilePasswordErrorSchema.get(err)),
    [update, navigate]
  );

  return (
    <Fragment>
      <Title>Зміна паролю</Title>
      <InlineSpace direction="vertical" />
      <Alert icon="info" className={styles.hint}>
        Пароль має містити щонайменше 8 символів, з них хоча б одну цифру, одну маленьку та одну
        велику латинські літери.
      </Alert>

      <Form<any> validate={yupResolver(ProfilePasswordSchema)} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field.Password label="Старий пароль" name="oldPassword" />
            <Row gutter={10}>
              <Col span={12}>
                <Field.Password label="Новий пароль" name="newPassword" />
              </Col>
              <Col span={12}>
                <Field.Password label="Повторити новий пароль" name="newPasswordConfirmation" />
              </Col>
            </Row>

            <InlineSpace direction="vertical" size={20} />

            <FormElement direction="row" justifyContent="flex-end">
              <Button variant="text" color="dark" onClick={handleDismiss}>
                Скасувати
              </Button>
              <Button width="wide" color="dark" type="submit">
                Зберегти
              </Button>
            </FormElement>
          </form>
        )}
      </Form>
    </Fragment>
  );
};
