import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useFindCustomerQuery, useUpdateCustomerMutation } from 'services/api/tms/customers/api';

import { CustomerForm } from '../../components/CustomerForm';
import styles from './CustomerEdit.module.scss';

export const CustomerEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: customer } = useFindCustomerQuery(String(id));
  const [update] = useUpdateCustomerMutation();

  const handleSubmit = useCallback(
    (values) => {
      update(values)
        .unwrap()
        .then(() => {
          navigate(ROUTES.customerShow(id));
        });
    },
    [id, update, navigate]
  );

  return (
    <Fragment>
      <div className={styles.card}>
        <PageHeader>Редагування клієнта</PageHeader>
        {customer ? (
          <CustomerForm defaultValues={customer?.data} onSubmit={handleSubmit} />
        ) : (
          <Preloader />
        )}
      </div>
    </Fragment>
  );
};
