import { tmsApi } from '../api';
import type { CoreResponse, Pagination } from 'types';
import type { ITask, TaskFilterQuery } from './types';

export const tasksApi = tmsApi.enhanceEndpoints({ addTagTypes: ['Task'] }).injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<CoreResponse<ITask[]>, Pagination<ITask> & TaskFilterQuery>({
      query: (params) => ({ url: '/tasks', params }),
      providesTags: ['Task'],
    }),
    findTask: builder.query<CoreResponse<ITask>, string>({
      query: (id) => ({ url: `/tasks/${id}` }),
      providesTags: ['Task'],
    }),
  }),
});

export const { useGetTasksQuery, useFindTaskQuery } = tasksApi;
