import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, Preloader } from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import { useAppSelector } from 'hooks/useAppStore';
import { useUpdateDeviceModelMutation } from 'services/api/tms/models/api';

import { selectModelById } from '../../slice';
import { ModelForm } from '../../components/ModelForm';

export const ModelEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const model = useAppSelector((state) => selectModelById(state, Number(id)));
  const [update] = useUpdateDeviceModelMutation();

  const handleDismiss = () => navigate(ROUTES.hardwareModels());
  const handleSubmit = useCallback(
    async (values) => {
      await update(values);

      navigate(ROUTES.hardwareModels());
    },
    [update, navigate]
  );

  return (
    <Fragment>
      <PageHeader>Редагування моделі</PageHeader>

      {model ? (
        <ModelForm defaultValues={model} onCancel={handleDismiss} onSubmit={handleSubmit} />
      ) : (
        <Preloader />
      )}
    </Fragment>
  );
};
