import { createSlice } from '@reduxjs/toolkit';

import { authApi } from 'services/api/tms/auth/api';
import { IAuthState } from './types';

const initialState: IAuthState = {
  accessToken: undefined,
  refreshToken: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut(state) {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
      const { access, refresh } = action.payload.data;

      state.accessToken = access;
      state.refreshToken = refresh;
    });
  },
});

export const { signOut } = authSlice.actions;
