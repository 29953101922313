import forEach from 'lodash/forEach';

/* SchemaObject Example
* {
*   email: {
*      taken: "errorName",
*      default: "Default",
*   },
*
*   default: "String",
* }
*

Example:
export const LoginFormErrorSchema = schemaError({
  default: "Default error message",
  email: {
    taken: "Phone number is taken",
    default: "Some phone error"
  }
})

LoginFormErrorSchema.get({ email: taken })
=> { email: "Phone number is taken" }

LoginFormErrorSchema.get({ email: some_other_error_code })
=> { email: "Some phone error" }

LoginFormErrorSchema.get({ key: some_other_error_code })
=> { email: "Default error message" }
* */

type SchemaObject = {
  [key: string]:
    | {
        [key: string]: any;
        default: string;
      }
    | string;

  default: string;
};
type ObjectAny = { [key: string]: any };

const schemaError = (schema: SchemaObject) => {
  const get = (err: any) => {
    if (err.message || err.stack) return err;

    const obj: ObjectAny = {};

    forEach(err.data, (value, fieldName) => {
      const schemaField = schema[fieldName];
      const schemaFieldValue = schemaField && schemaField[value];

      if (schemaField) {
        if (schemaFieldValue) {
          obj[fieldName] = schemaFieldValue;
        } else if (typeof schemaField === 'string') {
          obj[fieldName] = schemaField;
        } else if (schemaField.default) {
          obj[fieldName] = schemaField.default;
        }
      } else {
        obj.base = schema.default;
      }
    });

    return obj;
  };

  return { get };
};

export { schemaError };
