import { Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  Hint,
  IDataTableColumn,
  Icon,
  InlineSpace,
  PageHeader,
} from '@smart-kasa/ui';

import { ROUTES } from 'routes';
import useConfirm from 'hooks/useConfirm';
import { AttributeTable, DateView } from 'components';
import type { IDeviceVendor } from 'services/api/tms/vendors/types';
import { useDeleteVendorMutation } from 'services/api/tms/vendors/api';

import { useDeviceVendors } from '../../hooks/useDeviceVendors';

export const VendorList = () => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { vendors, isLoading } = useDeviceVendors();
  const [destroy] = useDeleteVendorMutation();

  const handleDelete = async (id: number) => {
    const isConfirmed = await confirm('Ви дійсно бажаєте видалити постачальника?');

    if (isConfirmed) destroy(id);
  };

  const columns: IDataTableColumn<IDeviceVendor>[] = [
    {
      key: 'id',
      title: 'ID',
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'name',
      width: '30%',
      title: 'Назва',
    },
    {
      key: 'address',
      width: '30%',
      title: 'Адреса',
    },
    {
      key: 'createdAt',
      title: 'Дата створення',
      render: (createdAt) => <DateView value={createdAt} />,
    },
    {
      key: 'id',
      title: 'Дії',
      render: (id) => (
        <Fragment>
          <ButtonIcon>
            <Icon size="large" name="edit" />
          </ButtonIcon>
          <InlineSpace />
          <ButtonIcon
            onClick={(event) => {
              handleDelete(id);
              event.stopPropagation();
            }}
          >
            <Icon size="large" name="trash" />
          </ButtonIcon>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        actions={
          <Button
            variant="outlined"
            color="dark"
            onClick={() => navigate(ROUTES.hardwareVendorCreate())}
          >
            Додати постачальника
          </Button>
        }
      >
        Список постачальників обладнання
      </PageHeader>

      <AttributeTable
        rowIdKey="id"
        data={vendors}
        isLoading={isLoading}
        columns={columns}
        emptyText="Список порожній"
        onRowClick={(item) => navigate(ROUTES.hardwareVendorEdit(item.id))}
      />

      <Outlet />
    </Fragment>
  );
};
