import { ChangeEvent, FC, useRef } from 'react';
import { Card, Hint, IInputProps, Icon, Input, Strong } from '@smart-kasa/ui';

import usePlacesAutocomplete from 'hooks/usePlacesAutocomplete';
import { getGeocode } from 'hooks/useGeocoder';
import useModal from 'hooks/useModal';
import useOnClickOutside from 'hooks/useClickOutside';

import styles from './PlacesAutocomplete.module.scss';

export type PlacesAutocompleteProps = Omit<IInputProps, 'onChange' | 'type' | 'value'> & {
  value?: string;
  onChange?: (result: google.maps.GeocoderResult) => void;
};

export const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({ value, onChange, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    ready,
    value: term,
    suggestions: { data, status },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: value,
    requestOptions: { language: process.env.REACT_APP_DEFAULT_LOCALE },
  });
  const { isShowing, showModal, hideModal } = useModal();

  useOnClickOutside(ref, hideModal);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSelect = async ({ description }) => {
    const results = await getGeocode({ address: description });

    if (!results) return;

    setValue(results[0].formatted_address, false);
    clearSuggestions();

    onChange && onChange(results[0]);
  };

  return (
    <div className={styles.autocomplete} ref={ref}>
      <Input
        suffixIcon={<Icon name="location" />}
        value={term}
        onChange={handleInput}
        onFocus={showModal}
        disabled={!ready}
        {...rest}
      />

      {isShowing && status === 'OK' && (
        <Card className={styles.dropdown}>
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <div className={styles.item} key={place_id} onClick={() => handleSelect(suggestion)}>
                <Strong>{main_text}</Strong>
                <Hint margin={0}>{secondary_text}</Hint>
              </div>
            );
          })}
        </Card>
      )}
    </div>
  );
};
