import { Field } from 'react-final-form';
import { FileInput } from '@smart-kasa/ui';

export const FileField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input, ...rest }) => (
      <FileInput
        {...input}
        {...rest}
        {...props}
        onChange={(files) => {
          const { multiple } = props;
          const [file] = files;

          return multiple ? input.onChange(files) : input.onChange(file);
        }}
      />
    )}
  </Field>
);
