import * as yup from 'yup';

const NAME_REGEX = new RegExp('[!@#$%^&*(),.?":{}|<>0-9]', 'g');

export const schemaPhone = yup
  .string()
  .matches(
    /((\+38)?\(?\d{3}\)?[\s.-]?(\d{7}|\d{3}[\s.-]\d{2}[\s.-]\d{2}|\d{3}-\d{4}))/,
    'Недопустимий формат. Вкажіть активний номер.'
  );

export const schemaAddress = yup.object().shape({
  content: yup.string().required(),
});

export const schemaPassword = yup
  .string()
  .min(8, 'Недостатня надійність. Пароль має містити щонайменше 8 символів')
  .matches(
    /[a-z]/,
    'Недостатня надійність. Пароль має містити хоча б одну маленьку латинську літеру'
  )
  .matches(/[0-9]/, 'Недостатня надійність. Пароль має містити хоча б одну цифру')
  .matches(
    /[A-Z]/,
    'Недостатня надійність. Пароль має містити хоча б одну велику латинську літеру'
  );

export const schemaName = yup
  .string()
  .test(
    'is-name',
    'Недопустимий формат. Вкажіть, будь ласка, реальні дані, для коректного відображення в подальшому',
    (value) => {
      if (!value) {
        return true;
      }
      return !(value.search(NAME_REGEX) + 1);
    }
  );
