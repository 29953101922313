import { createPathComponent } from '@react-leaflet/core';
import L, { LeafletEventHandlerFn } from 'leaflet';
import 'leaflet.markercluster';

export const PointCluster = createPathComponent(({ children: _c, ...props }, context) => {
  const clusterProps = {};
  const clusterEvents = {};

  // Splitting props and events to different objects
  Object.entries(props).forEach(([propName, prop]) =>
    propName.startsWith('on') ? (clusterEvents[propName] = prop) : (clusterProps[propName] = prop)
  );

  // Creating markerClusterGroup Leaflet element
  const markerClusterGroup = L.markerClusterGroup(clusterProps);

  // Initializing event listeners
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    markerClusterGroup.on(clusterEvent, callback as LeafletEventHandlerFn);
  });

  return {
    instance: markerClusterGroup,
    context: { ...context, layerContainer: markerClusterGroup },
  };
});
